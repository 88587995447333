import * as React from "react";
import { renderFormContent, FormContent } from "./cms";
import { formListener } from "../pages/lib";
import { CMSAPI } from "../lib/cms-api";
import { Form } from "semantic-ui-react";

interface Props {
  handleFormSubmit: (data: any) => void;
  handleValidStateUpdate: (state: boolean) => void;
  show: boolean;
  template_id: number;
}

const cms = new CMSAPI();

export const CMSContractForm = ({
  handleFormSubmit,
  handleValidStateUpdate,
  show,
  template_id
}: Props) => {
  const [formData, setFormData] = React.useState({});
  const listener = formListener(formData, setFormData);

  const [content, setContent] = React.useState<FormContent[]>([]);

  React.useEffect(() => {
    cms.fetchForm(template_id).then(form => {
      if (form) {
        setContent(form.content);
      }
    });
  }, []);

  const showConditions = [];
  let pointer = 0;
  const renderForm = () => {
    const renderData = [];
    while (pointer < content.length) {
      const component = content[pointer];
      if (component.__component == "form.start-sub-form") {
        showConditions.push(component.show_when_name_is_true);
        pointer++;
        renderData.push(renderForm());
        continue;
      }

      if (component.__component == "form.end-sub-form") {
        const showCondition = showConditions.pop();
        const isShow = formData[showCondition] || false;
        pointer++;
        return <>{isShow && renderData}</>;
      }

      renderData.push(
        renderFormContent(component, formData, handleValidStateUpdate, listener)
      );

      pointer++;
    }

    // if it ever reach here, it's the root form
    return renderData;
  };

  return (
    <Form
      onSubmit={() => handleFormSubmit(formData)}
      style={{ display: `${show ? "" : "none"}` }}
    >
      {renderForm()}
    </Form>
  );
};
