import * as React from 'react';
import {Item, Image, Table, Responsive} from 'semantic-ui-react';
import IphoneStepOne from '../../../static/iphone-step-1.jpg';
import IphoneStepOneA from '../../../static/iphone-step1-a.jpg';
import IphoneStepOneB from '../../../static/iphone-step1-b.jpg';
import IphoneStepOneC from '../../../static/iphone-step1-c.jpg';
import {Helmet} from 'react-helmet';

const ItemNumber = ({num}) => (
  <div style={{fontSize: '3em', marginRight: '20px'}}>{num}</div>
);

const ItemNumberMobile = ({num}) => (
  <span style={{fontSize: '2em', marginRight: '20px'}}>{num}</span>
);

export default function IPadIPhoneEmailSetup() {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  return (
    <div className="ipad-iphone-email-setup">
      <Helmet>
        <title>VelocityNet | iPhone, iPad email setup</title>
      </Helmet>
      <h1>iPhone, iPad email setup</h1>
      <Item.Group>
        <Item>
          {!isMobile && <ItemNumber num={1} />}
          <Item.Content>
            <Item.Header>
              {isMobile && <ItemNumberMobile num={1} />}Open account setting
            </Item.Header>
            <Item.Description>
              <p>
                The first step to setup your email is to open up the iPhone/iPad
                setting screen to view and update the configuration for your
                device. Afterwards, you need to navigate to the{' '}
                <b>Mail, Contacts, Calendars</b> setting by tapping on the
                mentioned setting and tap on <b>Add Account</b> when you have
                navigated to the <b>Mail, Contacts, Calendars</b> setting
                screen. Then tap on <b>Other</b> to add your VelocityNet email.
              </p>
              <br />
              <br />
              {!isMobile && (
                <Image.Group
                  size="medium"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}>
                  <Image src={IphoneStepOne} />
                  <Image src={IphoneStepOneA} />
                  <Image src={IphoneStepOneB} />
                  <Image src={IphoneStepOneC} />
                </Image.Group>
              )}
              {isMobile && (
                <>
                  <Image src={IphoneStepOne} fluid />
                  <br />
                  <Image src={IphoneStepOneA} fluid />
                  <br />
                  <Image src={IphoneStepOneB} fluid />
                  <br />
                  <Image src={IphoneStepOneC} fluid />
                  <br />
                </>
              )}
            </Item.Description>
          </Item.Content>
        </Item>
        <Item style={{marginTop: '50px'}}>
          {!isMobile && <ItemNumber num={2} />}
          <Item.Content>
            <Item.Header>
              {isMobile && <ItemNumberMobile num={2} />}Enter Mail information
            </Item.Header>
            <Item.Description>
              <p>
                The next step is to tap on <b>Add Mail Account</b> and enter the
                following information when asked:
              </p>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Name</Table.Cell>
                    <Table.Cell>Your Name</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Email Address</Table.Cell>
                    <Table.Cell>Your email address</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Password</Table.Cell>
                    <Table.Cell>
                      Obtain by calling 03-218-4489 if you dont know it
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Description</Table.Cell>
                    <Table.Cell>Your email address</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <p>
                Tap next then tap <b>IMAP</b> and enter these information
              </p>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Incoming hostname</Table.Cell>
                    <Table.Cell>imap.velocitynet.co.nz</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Incoming Username</Table.Cell>
                    <Table.Cell>Your full email address</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Incoming Password</Table.Cell>
                    <Table.Cell>Your email address password</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Outgoing hostname</Table.Cell>
                    <Table.Cell>smtp.velocitynet.co.nz</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Username</Table.Cell>
                    <Table.Cell>
                      Your full email address (not optional)
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Password</Table.Cell>
                    <Table.Cell>
                      Your email address password (not optional)
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <p>
                Then tap <b>Save</b> and wait for the verification process to
                complete.
              </p>
            </Item.Description>
          </Item.Content>
        </Item>
        <Item style={{marginTop: '50px'}}>
          {!isMobile && <ItemNumber num={3} />}
          <Item.Content>
            <Item.Header>
              {isMobile && <ItemNumberMobile num={3} />}Finishing up
            </Item.Header>
            <Item.Description>
              <p>
                The next step is to tap on your account on the list to edit its
                settings. Tap on <b>SMTP smtp.velocitynet.co.nz ></b> under the
                outgoing mail server section.
              </p>
              <p>
                <b>Tap smtp.velocitynet.co.nz ON ></b> under the primary server
                section and switch the <b>Use SSL</b> switch to <b>ON</b>. Then
                set the <b>Server port</b> to <b>587</b> or <b>465</b>.
              </p>
              <p>
                Finally, tap <b>Done</b> and your account should be available in
                the mail app.
              </p>
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    </div>
  );
}
