import {
  InputOnChangeData,
  TextAreaProps,
  SelectProps,
  FormCheckboxProps
} from "semantic-ui-react";

export interface FormListenerActions {
  directUpdate: (name: string, value: any) => void;
  handleInputChange: (
    name: string,
    cb?: (v: string) => void
  ) => (_: any, data: InputOnChangeData) => void;
  handleTextareaChange: (
    name: string,
    cb?: (v: string) => void
  ) => (_: any, data: TextAreaProps) => void;
  handleSelectChange: (
    name: string,
    cb?: (v: string) => void
  ) => (_: any, data: SelectProps) => void;
  handleDatePickerChange: (
    name: string,
    cb?: (v: string) => void
  ) => (_: any, data: { value: string }) => void;
  handleCheckBoxChange: (
    name: string,
    cb?: (v: boolean) => void
  ) => (_: any, data: FormCheckboxProps) => void;
  handleYesNoChange: (
    name: string,
    cb?: (v: boolean) => void
  ) => (value: boolean) => void;
}

export function formListener(
  formData: any,
  setFormData: (data: any) => void
): FormListenerActions {
  const updateFromData = (name: string, value: any) => {
    const parts = name.split(".");
    const temp = { ...formData };
    let root = temp;
    const last_key = parts.pop();
    for (const part of parts) {
      if (!root[part]) {
        root[part] = {};
      }
      root = root[part];
    }
    root[last_key] = value;
    setFormData(temp);
  };
  return {
    directUpdate: (name: string, value: any) => {
      updateFromData(name, value);
    },
    handleInputChange: (name: string, cb?: (v: string) => void) => (
      _,
      data: InputOnChangeData
    ) => {
      const value = data.value;
      updateFromData(name, value);
      if (cb) {
        cb(value);
      }
    },
    handleTextareaChange: (name: string, cb?: (v: string) => void) => (
      _,
      data: TextAreaProps
    ) => {
      const value = data.value;
      updateFromData(name, value);
      if (cb) {
        cb(value.toString());
      }
    },
    handleSelectChange: (name: string, cb?: (v: string) => void) => (
      _,
      data: SelectProps
    ) => {
      const value = data.value;
      updateFromData(name, value);
      if (cb) {
        cb(value.toString());
      }
    },
    handleDatePickerChange: (name: string, cb?: (v: string) => void) => (
      _,
      { value }
    ) => {
      updateFromData(name, value);
      if (cb) {
        cb(value);
      }
    },
    handleCheckBoxChange: (name: string, cb?: (v: boolean) => void) => (
      _,
      data: FormCheckboxProps
    ) => {
      const value = data.checked || false;
      updateFromData(name, value);
      if (cb) {
        cb(value);
      }
    },
    handleYesNoChange: (name: string, cb?: (v: boolean) => void) => (
      value: boolean
    ) => {
      updateFromData(name, value);
      if (cb) {
        cb(value);
      }
    }
  };
}

const formFormats = {
  driving_license_number: /^[A-Z]{2}\d{6}$/,
  driving_license_version: /^\d{3}$/,
  dob: /^\d{1,2}\/\d{1,2}\/\d{4}$/,
  date: /^\d{1,2}\/\d{1,2}\/\d{4}$/,
  phone: /^(\(?0\d\)?\d{7}|\(?02\d\)?\d{6,8}|0800\s\d{5,8})$/,
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

export const validateFormat = (
  format: string,
  value: string,
  cb?: (value: boolean) => void
) => {
  const regex = format.startsWith("r")
    ? new RegExp(format.substr(1))
    : formFormats[format];
  const result = !value || regex.test(value);
  if (cb) {
    cb(result);
  }
  return result;
};
