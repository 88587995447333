import * as React from "react";
import { Form, Responsive } from "semantic-ui-react";
import { validateFormat } from "../../lib";
import Reaptcha from "reaptcha";
import { reCaptchaSiteKey } from "../cred";
import { BankExample } from "../../../components/bank-example";

interface Props {
  show: boolean;
  handleValidStateUpdate: (valid: boolean) => void;
  handleFormSubmit: (data: any) => void;
}

export interface DirectPaymentFormData {
  bankcode: string;
  branchNumber: string;
  accountNumber: string;
  suffix: string;
  accountName: string;
}

export default function DirectPaymentForm(props: Props) {
  const { handleValidStateUpdate, handleFormSubmit } = props;
  const [acceptAuthority, setAcceptAuthority] = React.useState(false);
  const [bankcode, setBankCode] = React.useState("");
  const [branchNumber, setBranchNumber] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [suffix, setSuffix] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  const captcha = React.useRef();

  const [verified, setVerified] = React.useState(false);

  const onVerify = () => {
    setVerified(true);
  };

  const onExpired = () => {
    setVerified(false);
    (captcha.current as any).reset();
  };

  return (
    <Form
      onSubmit={() =>
        handleFormSubmit({
          bankcode,
          branchNumber,
          accountNumber,
          suffix,
          accountName,
        })
      }
      style={{ display: `${props.show ? "" : "none"}` }}
    >
      <h1>Billing details</h1>
      <Form.Input
        onChange={(_, { value }) => setAccountName(value)}
        fluid
        label="Account name"
        required
      />
      <Form.Group>
        <Form.Input
          error={
            !validateFormat("r^\\d{2}$", bankcode, handleValidStateUpdate)
              ? { content: "Your bank code is not in the valid format" }
              : null
          }
          onChange={(_, { value }) => setBankCode(value)}
          width={isMobile ? 4 : 2}
          maxLength={2}
          label="Bank code"
          required
        />
        <Form.Input
          error={
            !validateFormat("r^\\d{4}$", branchNumber, handleValidStateUpdate)
              ? { content: "Your bank code is not in the valid format" }
              : null
          }
          onChange={(_, { value }) => setBranchNumber(value)}
          width={isMobile ? 5 : 6}
          maxLength={4}
          label="Branch number"
          required
        />
        <Form.Input
          error={
            !validateFormat("r^\\d{7}$", accountNumber, handleValidStateUpdate)
              ? { content: "Your account number is not in the valid format" }
              : null
          }
          onChange={(_, { value }) => setAccountNumber(value)}
          width={isMobile ? 5 : 6}
          maxLength={7}
          label="Account number"
          required
        />
        <Form.Input
          error={
            !validateFormat("r^\\d{2}$", suffix, handleValidStateUpdate)
              ? { content: "Your suffix is not in the valid format" }
              : null
          }
          onChange={(_, { value }) => setSuffix(value)}
          width={2}
          maxLength={2}
          label="Suffix"
          required
        />
      </Form.Group>
      <BankExample />
      <div
        style={{
          width: "100%",
          height: "300px",
          overflow: "auto",
          border: "1px solid #d3d3d3",
          padding: "15px",
          borderRadius: "4px",
        }}
      >
        <h3>Authority to accept Direct Debits</h3>
        <ol>
          <li>
            <b>The Initiator</b>
            <ol>
              <li>
                Has agreed to give advance Notice of the net amount of each
                Direct Debit and the due date of debiting at least 10 calendar
                days before (but not more than 2 calendar months) the date the
                Direct Debit will be initiated. This notice will be provided
                either:
                <ol>
                  <li>in writing; or</li>

                  <li>
                    by any other means which provides a verifiable record of the
                    initiated transaction and where the Customer has provided
                    prior written consent to the Initiator.
                  </li>
                </ol>
                <br /> The advance notice will include the following message:
                <br />
                <br /> “Unless advice to the contrary is received from you by
                (*date), the amount of ($amount) will be directly debited to
                your Bank account on (initiating date).”
                <br />
                <br />
                <i>
                  * This date will be at least 2 days prior to the due date to
                  allow for amendment of Direct Debits.
                </i>
              </li>

              <li>
                May, upon the relationship which gave rise to this Authority
                being terminated, give notice to the Bank that no further Direct
                Debits are to be initiated under the Authority. Upon receipt of
                such notice the Bank may terminate this Authority as to future
                payments by notice in writing to me/us.
              </li>
            </ol>
            <br /> OR
            <br />
            <br />
            <ol>
              <li>
                Undertakes to give Notice to the Acceptor of the commencement
                date, frequency and amount at least 10 calendar days before the
                first Direct Debit is drawn (but not more than 2 calendar
                months). This notice will be provided either:
                <ol>
                  <li>in writing; or</li>

                  <li>
                    by any other means which provides a verifiable record of the
                    initiated transaction and where the Customer has provided
                    prior written consent to the Initiator.
                  </li>
                </ol>
                <br /> Where the Direct Debit system is used for the collection
                of payments which are regular as to frequency but variable as to
                amounts, the Initiator undertakes to provide the Acceptor with a
                schedule detailing each payment amount and each payment date.
                <br />
                <br /> In the event of any subsequent change to the frequency or
                amount of the Direct Debits, the Initiator has agreed to give
                advance notice at least 30 days before the change comes into
                effect. This notice must be provided either:
                <ol>
                  <li>in writing; or</li>

                  <li>
                    by electronic mail where the Customer has provided prior
                    written consent to the Initiator
                  </li>
                </ol>
              </li>

              <li>
                May, upon the relationship which gave rise to this Authority
                being terminated, give notice to the Bank that no further Direct
                Debits are to be initiated under the Authority. Upon receipt of
                such notice the Bank may terminate this Authority as to future
                payments by notice in writing to me/us.
              </li>
            </ol>
          </li>

          <li>
            <b>The Customer may:</b>

            <ol>
              <li>
                At any time, terminate this Authority as to future payments by
                giving written notice of termination to the Bank and to the
                Initiator.
              </li>

              <li>
                Stop payment of any Direct Debit to be initiated under this
                Authority by the Initiator by giving written notice to the Bank
                prior to the Direct Debit paid by the Bank.
              </li>

              <li>
                <p>
                  Where a variation to the amount agreed between the Initiator
                  and the Customer from time to time to be direct debited has
                  been made without notice being given in terms of clause 1 (a)
                  above, request the Bank to reverse or alter such Direct Debit
                  initiated by the Initiator by debiting the amount of the
                  reversal or alteration of a Direct Debit back to the Initiator
                  through the Initiator’s Bank. PROVIDED such a request is made
                  not more than 120 days from the date when the Direct Debit was
                  debited to my/our account.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <b>The Customer acknowledges that:</b>

            <ol>
              <li>
                This Authority will remain in full force and effect in respect
                of all Direct Debits made from my/our account in good faith
                notwithstanding my/our death, bankruptcy or other revocation of
                this Authority until actual notice of such event is received by
                the Bank.
              </li>

              <li>
                In any event this Authority is subject to any arrangement now or
                hereafter existing between me/us and the Bank in relation to
                my/our account.
              </li>

              <li>
                Any dispute as to the correctness or validity of an amount
                debited to my/our account shall not be the concern of the Bank
                except in so far as the Direct Debit has not been paid in
                accordance with this Authority. Any other dispute lies between
                me/us and the Initiator.
              </li>

              <li>
                Where the Bank has used reasonable care and skill in acting in
                accordance with this authority, the Bank accepts no
                responsibility or liability in relation of: − the accuracy of
                information about Direct Debits on Bank statements − any
                variations between notices given by the Initiator and the
                amounts of Direct Debits.
              </li>

              <li>
                The Bank is not responsible for, or under any liability in
                respect of the Initiator’s failure to give written advance
                notice correctly nor for the non−receipt or late receipt of
                notice by me/us for any reason whatsoever. In any such situation
                the dispute lies between me/us and the Initiator.
              </li>

              <li>
                <p>
                  Notice given by the Initiator in terms of clause 1 (a) to the
                  debtor responsible for the payment shall be effective notice.
                  Any communication necessary because the debtor responsible for
                  payment is a person other than me/us is a matter between me/us
                  and the debtor concerned.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <b>The Bank may:</b>

            <ol>
              <li>
                At its absolute discretion conclusively determine the order of
                priority of payment by it of any monies pursuant to this or any
                other order, authority, cheque or draft properly executed by
                me/us and given to or drawn on the Bank.
              </li>

              <li>
                At any time terminate this Authority as to future payments by
                notice in writing to me/us.
              </li>

              <li>
                Charge its current fees for this service in force from time to
                time.{" "}
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <Form.Checkbox
        checked={acceptAuthority}
        onChange={() => setAcceptAuthority(!acceptAuthority)}
        label="I agree to the conditions of this Authority to accept Direct Debits"
      />
      <Reaptcha
        sitekey={reCaptchaSiteKey}
        ref={captcha}
        onExpire={onExpired}
        onVerify={onVerify}
      />
      <Form.Button disabled={!verified} primary>
        Submit
      </Form.Button>
    </Form>
  );
}
