import {nanoid} from 'nanoid';
import axios, {AxiosInstance} from 'axios';
import config from './api-config';

export interface ChorusAddress {
  aid: string;
  label: string;
}

export interface ChorusAddressResult {
  results: ChorusAddress[];
  suggestions: ChorusAddress[];
}

export interface ChorusAddress {
  references: {
    tlc: number;
  };
}

export interface BroadbandDetails {
  fibreAvailabilityStatus: {
    fibreAvailabilityStatus: string;
  };
}

class ChorusAPI {
  address_endpoint: string = '/addresslookup/v1/addresses';
  boardband_endpoint: string = '/broadbandavailability/v1/services';

  client: AxiosInstance;
  constructor() {
    this.client = axios.create({
      baseURL: config.Chorus.url,
      headers: {
        'X-Chorus-Client-Id': config.Chorus.clientId,
        'X-Chorus-Client-Secret': config.Chorus.clientSecret,
      },
    });
  }

  searchAddress(searchValue: string): Promise<ChorusAddressResult> {
    const requestId = nanoid();
    return this.client
      .get(this.address_endpoint, {
        params: {q: searchValue, fuzzy: true},
        headers: {'X-Transaction-Id': requestId},
      })
      .then(response => {
        return response.data;
      });
  }

  getAddressTlc(aid: string): Promise<number> {
    const requestId = nanoid();
    return this.client
      .get(`${this.address_endpoint}/aid:${aid}`, {
        headers: {'X-Transaction-Id': requestId},
      })
      .then(response => (response.data as ChorusAddress).references.tlc);
  }

  getAddressByAid(aid: string): Promise<ChorusAddress> {
    const requestId = nanoid();
    return this.client
      .get(`${this.address_endpoint}/aid:${aid}`, {
        headers: {'X-Transaction-Id': requestId},
      })
      .then(response => response.data as ChorusAddress);
  }

  getBroadbandDetails(tlc: number): Promise<BroadbandDetails> {
    const requestId = nanoid();
    return this.client
      .get(`${this.boardband_endpoint}/tlc:${tlc}`, {
        headers: {'X-Transaction-Id': requestId},
      })
      .then(response => response.data);
  }
}

export default ChorusAPI;
