import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Responsive } from "semantic-ui-react";
import { CMSAPI, NavData, MenuItem, Menu } from "../../lib/cms-api";
import config from "../../lib/api-config";
import "../../styles/components/nav.sass";
import { cleanURL } from "../../lib/util";

const cms = new CMSAPI();

export default () => {
  const [isFixedNav, setFixedNav] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [navData, setNavData] = React.useState<NavData>(null);
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setFixedNav(true);
      } else {
        setFixedNav(false);
      }
    });
  });

  React.useEffect(() => {
    cms.fetchNav().then(setNavData);
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpen]);

  const NavLink = ({ children, to }: LinkProps) => {
    if (to.toString().startsWith("http")) {
      return <a href={to.toString()}>{children}</a>;
    }
    if (to[0] == "#") {
      return (
        <HashLink to={"/" + to} onClick={() => setOpen(false)}>
          {children}
        </HashLink>
      );
    }
    if (to.toString().startsWith("/#")) {
      return (
        <HashLink to={to} onClick={() => setOpen(false)}>
          {children}
        </HashLink>
      );
    }
    return (
      <Link
        to={(to.toString().startsWith("/") ? "" : "/") + to}
        onClick={() => setOpen(false)}
      >
        {children}
      </Link>
    );
  };

  if (!navData) {
    return null;
  }

  const getMenuLink = (menu: MenuItem | Menu) => {
    if (menu.custom_link) {
      return menu.custom_link;
    }
    return menu.link.web_url;
  };

  return (
    <>
      <nav
        className={`nav-bar ${isFixedNav ? "fixed" : ""} ${
          isMobile ? "mobile" : ""
        } ${isOpen ? "open" : ""}`}
      >
        <div className="container nav-content">
          <button
            className="mobile-nav-btn"
            name="main-menu-opener"
            onClick={() => setOpen(!isOpen)}
          >
            <i className={`fa fa-${isOpen ? "times" : "bars"}`} />
          </button>
          <Link to="/" aria-label="home page">
            <img
              src={cleanURL(config.CMS.url + "/" + navData.logo.url)}
              id="logo"
            />
          </Link>
          <div className="menu-container">
            <ul className="menu">
              {navData.menus.map(menu => {
                return (
                  <li className="menu-item" key={menu.name}>
                    {menu.link || menu.custom_link ? (
                      <NavLink to={getMenuLink(menu)}>{menu.name}</NavLink>
                    ) : (
                      menu.name
                    )}
                    {menu.menu_items.length > 0 ? (
                      <ul className="sub-menu">
                        {menu.menu_items.map(item => (
                          <li className="sub-menu-item" key={item.name}>
                            <NavLink to={getMenuLink(item)}>
                              {item.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
      {isFixedNav && (
        <div className={`nav-placeholder ${isMobile ? "mobile" : ""}`} />
      )}
    </>
  );
};
