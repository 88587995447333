import * as React from "react";
import { Image, Grid, Responsive } from "semantic-ui-react";
import Telephone from "../../../static/telephone.jpg";
import { Helmet } from "react-helmet";
import { Content, renderContent } from "../../components/cms";
import { CMSAPI } from "../../lib/cms-api";
import Preloader from "../../components/preloader";

interface PlanPageData {
  title: string;
  description: string;
  content: Content[];
}

const CMS = new CMSAPI();

export default function WhatAboutATelephone() {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  const [pageData, setPageData] = React.useState<PlanPageData>(null);

  React.useEffect(() => {
    CMS.fetchPage("telephone").then((data: PlanPageData) => {
      setPageData(data);
    });
  }, []);

  if (!pageData) {
    return <Preloader />;
  }
  return (
    <div className="what-about-a-telephone section">
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
      </Helmet>
      <h1>{pageData.title}</h1>
      <Grid>
        <Grid.Row columns={isMobile ? 1 : 2}>
          <Grid.Column>
            <Image src={Telephone} size="large" style={{ margin: "0 auto" }} />
          </Grid.Column>
          <Grid.Column>{renderContent(pageData.content)}</Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
