import * as React from "react";
import LoadingIcon from "./loading-icon";

export default () => {
  return (
    <section
      style={{ textAlign: "center", minHeight: "350px", marginTop: "50px" }}
    >
      <LoadingIcon />
      <h1>Loading</h1>
    </section>
  );
};
