import * as React from "react";
import Header from "./header";
import { Helmet } from "react-helmet";
import { Content, renderContent } from "./cms";
import { CMSAPI } from "../lib/cms-api";
import config from "../lib/api-config";
import { cleanURL } from "../lib/util";
import Preloader from "./preloader";
import Error403 from "../pages/errors/403";
import Error404 from "../pages/errors/404";

interface PlanPageData {
  title: string;
  description: string;
  banner?: { url: string };
  content: Content[];
}

const CMS = new CMSAPI();

export default function PlanPage({ dataUrl }: { dataUrl: string }) {
  const [pageData, setPageData] = React.useState<PlanPageData>(null);
  const [error, setError] = React.useState<number>(null);

  React.useEffect(() => {
    CMS.fetchPage(dataUrl)
      .then((data: PlanPageData) => {
        setPageData(data);
      })
      .catch((e) => {
        setError(e.response.status);
      });
  }, [dataUrl]);

  if (!pageData && !error) {
    return <Preloader />;
  }

  if (error == 403) {
    return <Error403 />;
  }

  if (error == 404) {
    return <Error404 />;
  }

  if (error) {
    return <Preloader />;
  }

  return (
    <div className={dataUrl}>
      <Helmet>
        <title>VelocityNet | {pageData.title}</title>
        <meta name="og:title" content={`VelocityNet | ${pageData.title}`} />
        <meta name="og:description" content={pageData.description} />
        <meta name="description" content={pageData.description} />
      </Helmet>
      {pageData.banner ? (
        <Header background={cleanURL(config.CMS.url + pageData.banner.url)}>
          <h1>{pageData.title}</h1>
          <p>{pageData.description} </p>
        </Header>
      ) : null}
      {renderContent(pageData.content)}
    </div>
  );
}
