import * as React from "react";
import { Service, CMSAPI, Plan } from "../../lib/cms-api";
import Preloader from "../../components/preloader";
import { PlanViewer } from "../plan-viewer";
import { Grid, Card, Table, Button, Responsive } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { RichTextComponent } from "./rich-text";

export type PlansContent = {
  __component: "find-out-plan.plans-content";
  name: string;
  content: string;
  services: Service[];
};

const cms = new CMSAPI();

const planPropsTitleStyle = {
  fontWeight: "bold",
  fontSize: "0.8em"
};

export const PlansContentComp = ({
  services,
  content
}: {
  services: Service[];
  content: string;
}) => {
  const [serviceDatas, setServiceDatas] = React.useState<Service[]>([]);
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;

  React.useEffect(() => {
    const ids = services.map(service => service.id);
    cms.fetchServices(ids).then(data => {
      setServiceDatas(data);
    });
  }, [services]);

  if (!serviceDatas) {
    return <Preloader />;
  }

  const renderPlans = (plans: { plan: Plan; link: string }[]) => (
    <Grid>
      <Grid.Row columns={isMobile ? 1 : 3}>
        {plans.map(({ plan, link }, index) => (
          <Grid.Column key={index}>
            <PlanCard plan={plan} link={link} />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );

  const PlanCard = ({ plan, link }: { plan: Plan; link: string }) => {
    const downloadSpeed = plan.plan_detail.find(
      p => p.name.trim().toLowerCase() == "download speed"
    );
    const uploadSpeed = plan.plan_detail.find(
      p => p.name.trim().toLowerCase() == "upload speed"
    );
    const price = plan.plan_detail.find(
      p => p.name.trim().toLowerCase() == "price"
    );
    return (
      <Card
        fluid
        centered
        className="plan-card"
        style={{ marginBottom: "30px", textAlign: "center" }}
      >
        <Card.Content extra>
          <Card.Header>{plan.name}</Card.Header>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            <div style={{ marginBottom: "20px" }}>
              {downloadSpeed && (
                <>
                  <p className="prop-title">Download speed</p>
                  <p className="prop-value">{downloadSpeed.value}</p>
                  <div className="divider" />
                </>
              )}
              {uploadSpeed && (
                <>
                  <p className="prop-title">Upload speed</p>
                  <p className="prop-value">{uploadSpeed.value}</p>
                  <div className="divider" />
                </>
              )}
              <p className="prop-title">Price</p>
              <p className="prop-value">{price?.value}</p>
            </div>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button negative as={Link} to={link}>
            View plan
          </Button>
        </Card.Content>
      </Card>
    );
  };

  const Business = () => {
    const services = serviceDatas.filter(
      service => service.is_business_service
    );
    const plans = services.reduce(
      (acc, service) => [
        ...acc,
        ...service.plans.map(plan => {
          return {
            plan,
            link: service.link
          };
        })
      ],
      []
    );
    return renderPlans(plans);
  };

  const Residential = () => {
    const services = serviceDatas.filter(
      service => !service.is_business_service
    );
    const plans = services.reduce(
      (acc, service) => [
        ...acc,
        ...service.plans.map(plan => {
          return {
            plan,
            link: service.link
          };
        })
      ],
      []
    );
    return renderPlans(plans);
  };

  return (
    <div>
      <RichTextComponent content={content} />
      <PlanViewer business={<Business />} residential={<Residential />} />
    </div>
  );
};
