import * as React from "react";
import { Message, Responsive } from "semantic-ui-react";

export const BankExample = () => {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  return (
    <Message info>
      <h3 style={{ textAlign: "center" }}>Bank account number example</h3>
      <p>All New Zealand bank account numbers follow this pattern:</p>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ? "20px" : "30px",
        }}
      >
        00 0000 0000000 000
      </p>
      <ul>
        <li>The first two numbers show the bankcode</li>
        <li>The next four digits show your branch number</li>
        <li>The next seven show your account number</li>
        <li>The next three (or two for some banks) show your account suffix</li>
      </ul>
      <p style={{ fontWeight: "bold" }}>Example:</p>
      <p>The account number: {isMobile && <br />} 03 1234 5678901 000</p>
      <p>
        Bankcode: 03
        <br />
        Branch number: 1234
        <br />
        Account number: 5678901
        <br />
        Suffix number: 00
        <br />
      </p>
    </Message>
  );
};
