import * as React from "react";

export type IFrame = {
  __component: "pages.iframe";
  url: string;
  width: string;
  height: string;
  center: boolean;
};

export const IFrameComponent = ({ comp }: { comp: IFrame }) => {
  const width = comp.width;
  const height = comp.height;
  const isCenter = comp.center;
  return (
    <iframe
      style={{
        width: width || "auto",
        height: height || "auto",
        border: "none",
        display: isCenter ? "block" : "",
        margin: isCenter ? "0 auto" : ""
      }}
      src={comp.url}
    ></iframe>
  );
};
