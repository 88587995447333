import * as React from 'react';
import _ from 'lodash';
import {Grid, Button, Search} from 'semantic-ui-react';
import AddressInput from '../../components/address-input';
import ChorusAPI, {ChorusAddress} from '../../lib/chorus-api';
import {Helmet} from 'react-helmet';

const chorusAPI = new ChorusAPI();

export default () => {
  const [isBusiness, setIsBusiness] = React.useState(false);
  const [broadband, setBroadband] = React.useState(null);

  return (
    <div className="address-checker" style={{minHeight: '400px'}}>
      <Helmet>
        <title>VelocityNet | Address checker</title>
      </Helmet>
      <h1>Address checker</h1>
      <p>
        Our services may varies in different locations. The service that you
        requested may not be available at your location but we might be able to
        offer you another service. Enter your address below to find out what
        kind of service is available at your location.
      </p>
      <br />
      <Grid verticalAlign="middle">
        <Grid.Column width={4}>
          <Button.Group>
            <Button negative={!isBusiness} onClick={() => setIsBusiness(false)}>
              Residential
            </Button>
            <Button.Or />
            <Button negative={isBusiness} onClick={() => setIsBusiness(true)}>
              Business
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column width={12}>
          <AddressInput
            required={false}
            onSelect={(_, __, result) => {
              chorusAPI.getAddressTlc(result.aid).then(tlc => {
                chorusAPI
                  .getBroadbandDetails(tlc)
                  .then(details => console.log(details));
              });
            }}
          />
        </Grid.Column>
        {broadband && (
          <Grid.Column width={16}>
            <h1>Yes</h1>
          </Grid.Column>
        )}
      </Grid>
    </div>
  );
};
