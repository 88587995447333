import * as React from "react";

interface Props {
  items: Item[];
  className: string;
}

interface Item {
  title: string;
  content: string;
}

export default (props: Props) => {
  const { items, className } = props;
  const [activeIndex, setActiveIndex] = React.useState(null);
  return (
    <ul className={`collapsible ${className}`}>
      {items.map((item, index) => (
        <li
          className={`item ${index == activeIndex ? "active" : ""}`}
          onClick={() => setActiveIndex(index == activeIndex ? null : index)}
          key={index}
        >
          <p className="item-title">
            <span>{item.title}</span>
            <i className="fa fa-chevron-down down-icon" />
          </p>
          <p
            className="item-content"
            dangerouslySetInnerHTML={{ __html: item.content }}
          ></p>
        </li>
      ))}
    </ul>
  );
};
