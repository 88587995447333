import * as React from "react";
import { Form } from "semantic-ui-react";
import { FormListenerActions } from "../../pages/lib";

export type CheckBox = {
  __component: "form.check-box";
  label: string;
  name: string;
  is_required: boolean;
};

export const CheckBoxComponent = ({
  label,
  name,
  is_required,
  listener
}: {
  label: string;
  name: string;
  is_required: boolean;
  listener: FormListenerActions;
}) => {
  return (
    <Form.Checkbox
      onChange={listener.handleCheckBoxChange(name)}
      required={is_required}
      label={label}
    />
  );
};
