import * as React from "react";

import Preloader from "../../components/preloader";
import { CMSAPI } from "../../lib/cms-api";
import {
  renderFindOutPlanContent,
  FindOutPlanContent
} from "../../components/cms";

interface PlanPageData {
  title: string;
  description: string;
  content: FindOutPlanContent[];
}

const CMS = new CMSAPI();

function reducer(state: string[], action: { type: string; payload: any }) {
  switch (action.type) {
    case "jump":
      const { location, index } = action.payload;
      if (state[index]) {
        return [...state.slice(0, index), location];
      }
      return [...state, location];
  }
}

export default function WhatPlanCanIGet() {
  const [pageData, setPageData] = React.useState<PlanPageData>(null);
  const [renderable, dispatch] = React.useReducer(reducer, []);

  const sort_by_renderable = (a: any, b: any) => {
    return renderable.indexOf(a[0]) - renderable.indexOf(b[0]);
  };

  React.useEffect(() => {
    CMS.fetchPage("/find-out-plan").then((data: PlanPageData) => {
      setPageData(data);
      dispatch({ type: "jump", payload: { location: "question1", index: 0 } });
    });
  }, []);

  if (!pageData) {
    return <Preloader />;
  }

  const setJump = (location: string, index: number) => {
    dispatch({ type: "jump", payload: { location, index } });
  };

  const order_renders = [];

  return (
    <div className="find-out-plan" style={{ minHeight: "350px" }}>
      {pageData.content.map(content => {
        const rendered = renderFindOutPlanContent(content, renderable, setJump);
        switch (content.__component) {
          case "find-out-plan.multiple-choice":
          case "find-out-plan.multiple-choice-with-picture":
          case "find-out-plan.plans-content":
          case "find-out-plan.contact-content":
            if (rendered) {
              order_renders.push([content.name, rendered]);
            }
            return;
        }
        return rendered;
      })}
      {order_renders.sort(sort_by_renderable).map(rendered => rendered[1])}
    </div>
  );
}
