import * as React from "react";
import AI from "../address-input";
import ChorusAPI from "../../lib/chorus-api";
import { FormListenerActions } from "../../pages/lib";
const chorusAPI = new ChorusAPI();

export type AddressInput = {
  __component: "form.address-input";
  label: string;
  name: string;
  is_required: boolean;
};

export const AddressInputComponent = ({
  label,
  name,
  is_required,
  listener
}: {
  label: string;
  name: string;
  is_required: boolean;
  listener: FormListenerActions;
}) => {
  return (
    <AI
      label={label}
      required={is_required}
      onSelect={(_, { value }, result) => {
        listener.handleTextareaChange(name)(null, {
          value
        });
        chorusAPI.getAddressByAid(result.aid).then(result => {
          listener.directUpdate(
            `${name}.city`,
            result["structuredAddress"]["town"]
          );
          listener.directUpdate(
            `${name}.zip_code`,
            result["structuredAddress"]["postcode"]
          );
        });
      }}
      onChange={listener.handleTextareaChange(name)}
    />
  );
};
