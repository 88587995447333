import * as React from "react";
import { Button, Grid, Card, Image, Responsive } from "semantic-ui-react";
import Lightbox from "react-image-lightbox";
import Config from "../../lib/api-config";
import { cleanURL } from "../../lib/util";

export type MultipleChoiceWithPictureQuestionFOP = {
  __component: "find-out-plan.multiple-choice-with-picture";
  question: string;
  choices: Choice[];
  name: string;
  step: number;
};

export type Choice = {
  label: string;
  jump_to_name: string;
  picture: { url: string };
};

export const MultipleChoiceWithPictureCompFOP = ({
  step,
  question,
  choices,
  setJump
}: {
  step: number;
  question: string;
  choices: Choice[];
  setJump: (location: string, index: number) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  const [isShowPicture, setShowPicture] = React.useState(false);
  const [pictureIndex, setPictureIndex] = React.useState(0);

  const openImage = (index: number) => {
    setPictureIndex(index);
    setShowPicture(true);
  };

  React.useEffect(() => {
    let choice = choices[selectedIndex];

    if (!choice) {
      return;
    }

    setJump(choice.jump_to_name, step + 1);
  }, [selectedIndex]);

  const choiceButtons = choices.map((choice, idx) => {
    const selected = selectedIndex === idx;
    return (
      <Grid.Column key={idx}>
        <Card fluid style={{ marginBottom: "30px" }}>
          <Card.Content>
            <Image
              src={cleanURL(Config.CMS.url + "/" + choice.picture.url)}
              wrapped
              width="100%"
              onClick={() => openImage(idx)}
              ui={false}
            />
            <br />
            <Card.Header textAlign="center">{choice.label}</Card.Header>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button negative={selected} onClick={() => setSelectedIndex(idx)}>
              Select
            </Button>
          </Card.Content>
        </Card>
        {isShowPicture && pictureIndex == idx && (
          <Lightbox
            mainSrc={cleanURL(
              Config.CMS.url + "/" + choices[pictureIndex].picture.url
            )}
            onCloseRequest={() => setShowPicture(false)}
          />
        )}
      </Grid.Column>
    );
  });

  return (
    <div>
      <h2>Question {step + 1}:</h2>
      <p>{question}</p>

      <Grid>
        <Grid.Row columns={isMobile ? 1 : 3}>{choiceButtons}</Grid.Row>
      </Grid>
    </div>
  );
};
