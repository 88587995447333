import * as React from "react";
import ChorusAPI, { ChorusAddress } from "../lib/chorus-api";
import { Button, Search } from "semantic-ui-react";

const chorusAPI = new ChorusAPI();
let searchQueue = null;

interface Props {
  onSelect: (_: any, { value: string }, result: ChorusAddress) => void;
  onChange?: (_: any, { value: string }) => void;
  label?: string;
  required?: boolean;
}

export default ({ onSelect, onChange, required, label }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);

  const handleSearchChange = (_: any, { value }) => {
    setIsLoading(true);
    setSearchValue(value);
    if (onChange) {
      onChange(null, { value });
    }

    if (searchQueue) {
      clearTimeout(searchQueue);
    }

    searchQueue = setTimeout(() => {
      chorusAPI.searchAddress(searchValue).then(result => {
        setIsLoading(false);
        if (result.results.length == 0) {
          return;
        }
        setSearchResult(
          result.results.map(res => ({ ...res, title: res.label }))
        );
      });
    }, 500);
  };

  const handleResultSelect = (_: any, { result }) => {
    setSearchValue(result.label);
    onSelect(null, { value: result.label }, result);
  };

  return (
    <div className={`field ${required ? "required" : ""}`}>
      {label && <label>{label}</label>}
      <Search
        loading={isLoading}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        input={{
          fluid: true,
          icon: "map marker alternate",
          iconPosition: "left",
          placeholder: "Search address..."
        }}
        required={required}
        value={searchValue}
        results={searchResult}
        fluid={true}
        resultRenderer={({ label }) => <p key={label}>{label}</p>}
      />
    </div>
  );
};
