import * as React from "react";
import { Grid, Divider, Icon, Responsive } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import VNLogo from "../../../static/VNLogo.png";
import "../../styles/components/footer.sass";

export default () => {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  return (
    <footer>
      <div className="container">
        <Grid>
          <Grid.Row columns={isMobile ? 1 : 2}>
            <Grid.Column>
              <img
                src={VNLogo}
                className={`logo ${isMobile ? "mobile" : ""}`}
              />
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={isMobile ? 1 : 2}>
                  <Grid.Column>
                    <h2>Links</h2>
                    <ul>
                      <li>
                        <Link to="/terms-and-conditions">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <HashLink to="/#faq">FAQs</HashLink>
                      </li>
                      <li>
                        <a href="https://app.chorus.co.nz/">
                          Fibre install tracking
                        </a>
                      </li>
                      <li>
                        <Link to="/speed-test">Speed test</Link>
                      </li>
                    </ul>
                  </Grid.Column>
                  <Grid.Column>
                    <h2>Support</h2>
                    <ul>
                      <li>
                        <HashLink to="/#contact-us">Contact Us</HashLink>
                      </li>
                      <li>
                        <a href="https://status.velocitynet.co.nz/">
                          Network Status
                        </a>
                      </li>
                      <li>
                        <a href="http://connect.velocitynet.co.nz/">
                          Remote Support
                        </a>
                      </li>
                      <li>
                        <Link to="/iphone-ipad-email-setup">
                          iPad-iPhone Email Setup
                        </Link>
                      </li>
                      <li>
                        <Link to="/phone-feature-codes">
                          Phone Feature Codes
                        </Link>
                      </li>
                    </ul>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isMobile ? (
          <>
            <div style={{ textAlign: "center" }}>
              <a href="https://www.facebook.com/velocitynetltd/">
                <Icon size="big" name="facebook" />
              </a>
            </div>
            <Divider />
            <p style={{ textAlign: "center" }}>
              Copyright © {new Date().getFullYear()} VelocityNet | E&OE
            </p>
          </>
        ) : (
          <>
            <Divider />
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <a href="https://www.facebook.com/velocitynetltd/">
                    <Icon size="big" name="facebook" />
                  </a>
                </Grid.Column>
                <Grid.Column>
                  <p style={{ textAlign: "right" }}>
                    Copyright © {new Date().getFullYear()} VelocityNet | E&OE
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </div>
    </footer>
  );
};
