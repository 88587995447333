import * as React from "react";
import { Link } from "react-router-dom";
import { IHomePageData } from "../index";
import config from "../../../lib/api-config";
import { cleanURL } from "../../../lib/util";

interface IProps {
  data: IHomePageData;
}

export default (props: IProps) => {
  const { data } = props;
  return (
    <section className="solution">
      <div className="container">
        <h1 className="title">{data.solution_title}</h1>
        <p className="description">{data.solution_subtitle}</p>
        <div className="services">
          <ul>
            {data.solution_list.solution_item.map(solution => (
              <li key={solution.name}>
                <div className="solution-icon">
                  <img src={cleanURL(config.CMS.url + solution.icon.url)} />
                </div>
                <span className="solution-name">{solution.name}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="internet-services">
          {data.service_list.Service_panel.map(panel => (
            <div className="internet-service" key={panel.id} id="business">
              <div className="mask"></div>
              <img src={cleanURL(config.CMS.url + panel.background.url)} />
              <div className="content">
                <h2>{panel.title}</h2>
                <ul>
                  {panel.services.map(service => (
                    <li key={service.link}>
                      <Link to={service.link}>
                        {service.display_name || service.name}
                        <i className="fa fa-chevron-right" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
