import * as React from "react";
import PlansTable, { Plan } from "../../components/new/plans-table";

export type PlansTable = {
  __component: "services.plan-table";
  is_capped: boolean;
  show_gst: boolean;
  unround_price?: boolean;
  service: {
    id: number;
    is_business_service: boolean;
    plans: Plan[];
  };
};

export const PlansTableComponent = ({ comp }: { comp: PlansTable }) => {
  const showGst = comp.show_gst;
  const isBusiness = comp.service.is_business_service;
  const unRoundPrice = comp.unround_price;
  const plans = comp.service.plans.filter(
    // convert falsy & truthy to boolean
    plan => !!plan.is_capped == !!comp.is_capped
  );
  return (
    <PlansTable
      unRoundPrice={unRoundPrice}
      plans={plans}
      isBusiness={isBusiness}
      showGst={showGst}
    />
  );
};
