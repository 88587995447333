import * as React from "react";
import { RichTextComponent } from "./rich-text";
import ContactInfo from "../../components/contact-info";

export type ContactContent = {
  __component: "find-out-plan.contact-content";
  content: string;
  name: string;
};

export const ContactContentComp = ({ content }: { content: string }) => {
  return (
    <div>
      <RichTextComponent content={content} />
      <h2>Contact Us</h2>
      <ContactInfo />
    </div>
  );
};
