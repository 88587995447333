import * as React from "react";
import PI from "../phone-input";
import { validateFormat, FormListenerActions } from "../../pages/lib";

export type PhoneInput = {
  __component: "form.phone-input";
  label: string;
  name: string;
  is_required: boolean;
};

export const PhoneInputComponent = ({
  label,
  name,
  is_required,
  listener,
  value,
  handleValidStateUpdate
}: {
  label: string;
  name: string;
  is_required: boolean;
  listener: FormListenerActions;
  value: string;
  handleValidStateUpdate: (_: boolean) => void;
}) => {
  return (
    <PI
      label={label}
      required={is_required}
      error={
        !validateFormat("phone", value, handleValidStateUpdate)
          ? { content: "Your phone number is not in the valid format" }
          : null
      }
      onChange={listener.handleInputChange(name)}
    />
  );
};
