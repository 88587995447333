import * as React from "react";
import { Responsive } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import "../../styles/home-new.sass";
import { CMSAPI } from "../../lib/cms-api";

import Introduction from "./sections/introduction";
import Solutions from "./sections/solutions";
import ViewsFromUs from "./sections/views-from-us";
import FAQ from "./sections/faq";
import Contact from "./sections/contact";
import Footer from "../../components/new/footer";
import Preloader from "../../components/preloader";

export interface PartnerImage {
  url: string;
}

export interface Partners {
  title: string;
  partner_images: PartnerImage[];
}

export interface Icon {
  url: string;
}

export interface SolutionItem {
  id: number;
  name: string;
  icon: Icon;
}

export interface SolutionList {
  id: number;
  solution_item: SolutionItem[];
}

export interface Background {
  url: string;
}

export interface Service {
  id: number;
  name: string;
  display_name: string;
  is_business_service?: boolean;
  link: string;
}

export interface ServicePanel {
  id: number;
  title: string;
  background: Background;
  services: Service[];
}

export interface ServiceList {
  id: number;
  Service_panel: ServicePanel[];
}

export interface Faq {
  id: number;
  question: string;
  answer: string;
  created_by: number;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
}

export interface FAQList {
  id: number;
  faqs: Faq[];
}

export interface Map {
  url: string;
}

export interface BusinessHour {
  id: number;
  days_of_week: string;
  time: string;
}

export interface Address {
  id: number;
  type: string;
  address: string;
  created_by: number;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
}

export interface Phone {
  id: number;
  name: string;
  number: string;
  created_by: number;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
}

export interface Email {
  id: number;
  name: string;
  email: string;
  created_by: number;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
}

export interface InfoCard {
  id: number;
  business_hour: BusinessHour;
  addresses: Address[];
  phones: Phone[];
  emails: Email[];
}

export interface Gallery {
  images: { url: string }[];
}

export interface IHomePageData {
  title: string;
  subtitle: string;
  solution_title: string;
  solution_subtitle: string;
  views_from_us_title: string;
  views_from_us_subtitle: string;
  views_gallery: Gallery;
  FAQ_title: string;
  FAQ_subtitle: string;
  Contact_us_title: string;
  Contact_us_subtitle: string;
  partners: Partners;
  solution_list: SolutionList;
  service_list: ServiceList;
  faqs: FAQList;
  map: Map;
  info_card: InfoCard;
  introduction_image: { url: string };
}

const CMS = new CMSAPI();

export default function Home() {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  const [pageData, setPageData] = React.useState<IHomePageData>(null);

  React.useEffect(() => {
    CMS.fetchPage("homepage").then((data: IHomePageData) => {
      setPageData(data);
    });
  }, []);

  React.useLayoutEffect(() => {
    if (location.hash) {
      const el = document.querySelector(location.hash);
      if (el) {
        el.scrollIntoView();
      }
    }
  }, [pageData]);

  if (!pageData) {
    return <Preloader />;
  }

  return (
    <div className={`home-page ${isMobile ? "mobile" : ""}`}>
      <Helmet>
        <title>VelocityNet</title>
        <meta
          name="description"
          content="VelocityNet offers solutions for Residentials, Businesses & Rural communities"
        />
      </Helmet>
      <Introduction data={pageData} />
      <Solutions data={pageData} />
      <ViewsFromUs data={pageData} />
      <FAQ data={pageData} />
      <Contact data={pageData} />
      <Footer />
    </div>
  );
}
