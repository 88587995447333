import * as React from 'react';
import {Table, Responsive, Card, Grid, GridColumn} from 'semantic-ui-react';
import {Helmet} from 'react-helmet';

const showWithGST = true; // Change this to show with or without GST

// NOTICE: The price below is GST excluded
const nationalPrices = [
  {
    destination: 'New Zealand',
    landlines: 0.16,
    mobile: 0.32,
  },
];

const internationalPrices = [
  {destination: 'American Samoa', landlines: 1.09, mobile: 1.09},
  {destination: 'Australia', landlines: 0.22, mobile: 0.53, cap: 2.42},
  {destination: 'Canada *', landlines: 0.22, mobile: 0.22, cap: 4.41},
  {destination: 'China', landlines: 0.22, mobile: 0.22, cap: 7.06},
  {destination: 'Fiji', landlines: 0.53, mobile: 0.77},
  {destination: 'France', landlines: 0.22, mobile: 0.63, cap: 5.29},
  {destination: 'Germany', landlines: 0.22, mobile: 0.53, cap: 5.29},
  {destination: 'Hong Kong', landlines: 0.22, mobile: 0.2, cap: 7.06},
  {destination: 'India', landlines: 0.9, mobile: 0.9},
  {destination: 'Ireland', landlines: 0.22, mobile: 0.53, cap: 4.41},
  {destination: 'Italy', landlines: 0.22, mobile: 0.63, cap: 5.29},
  {destination: 'Japan', landlines: 0.22, mobile: 0.63, cap: 7.06},
  {destination: 'Malaysia', landlines: 0.35, mobile: 0.45, cap: 7.06},
  {destination: 'Netherlands', landlines: 0.22, mobile: 0.71, cap: 5.29},
  {destination: 'Phillipines', landlines: 0.45, mobile: 0.63},
  {destination: 'Singapore', landlines: 0.36, mobile: 0.36, cap: 7.06},
  {destination: 'South Africa', landlines: 0.69, mobile: 0.69, cap: 8.82},
  {destination: 'South Korea', landlines: 0.22, mobile: 0.63, cap: 7.06},
  {destination: 'Switzerland', landlines: 0.22, mobile: 0.81, cap: 5.29},
  {destination: 'Taiwan', landlines: 0.22, mobile: 0.63, cap: 7.06},
  {destination: 'Thailand', landlines: 0.63, mobile: 0.63},
  {destination: 'USA *', landlines: 0.18, mobile: 0.18, cap: 4.41},
  {destination: 'UK', landlines: 0.18, mobile: 0.53, cap: 4.41},
  {destination: 'Western Samoa', landlines: 1.09, mobile: 1.09},
  {
    destination: 'All other destinations',
    landlines: 1.26,
    mobile: 1.26,
    active: true,
  },
];

export default function RBIVoiceCallingRates() {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  const renderNationalPrices = () => {
    if (isMobile) {
      return (
        <>
          <h2>National prices</h2>

          {nationalPrices.map((price, i) => (
            <Card fluid key={i}>
              <Card.Content>
                <Grid divided="vertically" fluid>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p>Destination</p>
                    </Grid.Column>
                    <Grid.Column width={6}>{price.destination}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p>National landlines price per minute after 2 hours</p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      $
                      {showWithGST
                        ? (price.landlines * 1.15).toFixed(2)
                        : price.landlines}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p>Mobile price per minute</p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      $
                      {showWithGST
                        ? (price.mobile * 1.15).toFixed(2)
                        : price.mobile}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          ))}
        </>
      );
    }
    return (
      <>
        <h2>National prices</h2>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>
                National landlines price per minute after 2 hours
              </Table.HeaderCell>
              <Table.HeaderCell>Mobile price per minute</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {nationalPrices.map(price => (
              <Table.Row key={price.destination}>
                <Table.Cell>{price.destination}</Table.Cell>
                <Table.Cell>
                  $
                  {showWithGST
                    ? (price.landlines * 1.15).toFixed(2)
                    : price.landlines}
                </Table.Cell>
                <Table.Cell>
                  $
                  {showWithGST
                    ? (price.mobile * 1.15).toFixed(2)
                    : price.mobile}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  };
  const renderInternationalPrices = () => {
    if (isMobile) {
      return (
        <>
          <h2>International prices</h2>
          {internationalPrices.map((price, i) => (
            <Card fluid key={i}>
              <Card.Content>
                <Grid divided="vertically" fluid>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p>Destination</p>
                    </Grid.Column>
                    <Grid.Column width={6}>{price.destination}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p>
                        International landlines price per minute after 2 hours
                      </p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      $
                      {showWithGST
                        ? (price.landlines * 1.15).toFixed(2)
                        : price.landlines}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p>International mobile price per minute</p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      $
                      {showWithGST
                        ? (price.mobile * 1.15).toFixed(2)
                        : price.mobile}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p>Cap to landline calls up to 2 hours **</p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      {price.cap && '$'}
                      {price.cap ? (
                        showWithGST ? (
                          (price.cap * 1.15).toFixed(2)
                        ) : (
                          price.cap
                        )
                      ) : (
                        <b>No Capped Calling</b>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          ))}
          <p>
            <b>
              *: Calls to USA and Canada mobiles are also applicable for Capped
              calling
            </b>
          </p>
          <p>
            <b>
              **: Capped calling-Calls are capped at 2 hours then charged at the
              per minute international
            </b>
          </p>
        </>
      );
    }
    return (
      <>
        <h2>International prices</h2>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Destinations</Table.HeaderCell>
              <Table.HeaderCell>
                International landlines price
                <br />
                per minute after 2 hours
              </Table.HeaderCell>
              <Table.HeaderCell>
                International mobile price <br />
                per minute
              </Table.HeaderCell>
              <Table.HeaderCell>
                Cap to landline calls up to 2 hours **
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {internationalPrices.map(price => (
              <Table.Row active={price.active} key={price.destination}>
                <Table.Cell>{price.destination}</Table.Cell>
                <Table.Cell>
                  $
                  {showWithGST
                    ? (price.landlines * 1.15).toFixed(2)
                    : price.landlines}
                </Table.Cell>
                <Table.Cell>
                  $
                  {showWithGST
                    ? (price.mobile * 1.15).toFixed(2)
                    : price.mobile}
                </Table.Cell>
                <Table.Cell>
                  {price.cap && '$'}
                  {price.cap ? (
                    showWithGST ? (
                      (price.cap * 1.15).toFixed(2)
                    ) : (
                      price.cap
                    )
                  ) : (
                    <b>No Capped Calling</b>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <p>
          <b>
            *: Calls to USA and Canada mobiles are also applicable for Capped
            calling
          </b>
        </p>
        <p>
          <b>
            **: Capped calling-Calls are capped at 2 hours then charged at the
            per minute international
          </b>
        </p>
      </>
    );
  };
  const renderAddionalBundles = () => {
    if (isMobile) {
      return (
        <>
          <h2>Additional bundles</h2>
          <Card fluid>
            <Card.Content>
              <Card.Header>NZ and Australia</Card.Header>
            </Card.Content>
            <Card.Content>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>Per month</Grid.Column>
                  <Grid.Column>
                    ${showWithGST ? (22.05 * 1.15).toFixed(2) : 22.05}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>Gives you</Grid.Column>
                  <Grid.Column>
                    Calls to NZ and Australian landlines anytime (excluding
                    Norfolk island)
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>International calls</Card.Header>
            </Card.Content>
            <Card.Content>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>Per month</Grid.Column>
                  <Grid.Column>
                    ${showWithGST ? (28.72 * 1.15).toFixed(2) : 28.72}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>Gives you</Grid.Column>
                  <Grid.Column>
                    Calls to landlines in 21 countries as below
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        </>
      );
    }
    return (
      <>
        <h2>Additional bundles</h2>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Per month</Table.HeaderCell>
              <Table.HeaderCell>Gives you</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>NZ and Australia</Table.Cell>
              <Table.Cell>
                ${showWithGST ? (22.05 * 1.15).toFixed(2) : 22.05}
              </Table.Cell>
              <Table.Cell>
                Calls to NZ and Australian landlines anytime (excluding Norfolk
                island)
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>International calls</Table.Cell>
              <Table.Cell>
                ${showWithGST ? (28.72 * 1.15).toFixed(2) : 28.72}
              </Table.Cell>
              <Table.Cell>
                Calls to landlines in 21 countries as below
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );
  };
  return (
    <div className="rbi-voice-calling-rates">
      <Helmet>
        <title>VelocityNet | RBI Voice calling charges</title>
      </Helmet>
      <h1>RBI Voice calling charges</h1>
      <p>
        <b>Prices include GST</b>
      </p>
      {renderNationalPrices()}
      {renderInternationalPrices()}
      {renderAddionalBundles()}
      <p>International countries included in bundle:</p>
      <ul>
        <li>Argentina</li>
        <li>Australia</li>
        <li>Belgium</li>
        <li>Canada</li>
        <li>China</li>
        <li>France</li>
        <li>Germany</li>
        <li>Hong Kong</li>
        <li>Ireland</li>
        <li>Italy</li>
        <li>Japan</li>
        <li>Netherlands</li>
        <li>Singapore</li>
        <li>South Korea</li>
        <li>Spain</li>
        <li>Sweden</li>
        <li>Switzerland</li>
        <li>Taiwan</li>
        <li>UK and USA</li>
      </ul>
      <p>
        <b>
          Calls are capped at 2 hours, then charged at the per minute
          international landlines price.
        </b>
      </p>
    </div>
  );
}
