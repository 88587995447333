import * as React from "react";

import { Accordion, Icon } from "semantic-ui-react";

export type Collapsible = {
  __component: "pages.collapsible";
  label: string;
  content: string;
};

export const CollapsibleComponent = ({
  label,
  content
}: {
  label: string;
  content: string;
}) => {
  const [isOpenList, setOpenList] = React.useState(false);
  return (
    <Accordion>
      <Accordion.Title
        active={isOpenList}
        onClick={() => setOpenList(!isOpenList)}
      >
        <Icon name="dropdown" />
        <b>{label}</b>
      </Accordion.Title>
      <Accordion.Content active={isOpenList}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Accordion.Content>
    </Accordion>
  );
};
