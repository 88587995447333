const isProduction = process.env.NODE_ENV === "production";

export default {
  Chorus: {
    url: isProduction
      ? "https://api.chorus.co.nz/"
      : "https://api.sandbox.chorus.co.nz/",
    clientId: isProduction
      ? "09d1ffbe7bfe457dae8084c8e77b0f03"
      : "db203a04e9f9401c9a7029ca11ad9d17",
    clientSecret: isProduction
      ? "13BA26cC03C94CBa8204c3378A4cF49E"
      : "773f6c5caa434E4BA13a75504c8b9043"
  },
  CMS: {
    url: isProduction
      ? "https://cms.velocitynet.co.nz"
      : "http://" + location.hostname + ":1337/"
  }
};
