import * as React from 'react';
import {Form} from 'semantic-ui-react';
import {formListener} from '../../../lib';

export interface PhoneData {
  provider: {
    name: string;
    account_name: string;
    account_number: string;
  };
  number_porting: boolean;
  ported_numbers: string;
  disconnected_numbers: string;
  service: number;
}

interface Props {
  onChange: (data: PhoneData) => void;
  isPortingRequest?: boolean;
  plans: {key: number; text: string; value: number}[];
}

export default (props: Props) => {
  const {plans, isPortingRequest} = props;
  const [number_porting, set_number_porting] = React.useState(false);
  const [phoneData, setPhoneData] = React.useState({
    provider: {
      name: '',
      account_name: '',
      account_number: '',
    },
    number_porting: false,
    ported_numbers: '',
    disconnected_numbers: '',
    service: 1,
  } as PhoneData);
  React.useEffect(() => {
    if (isPortingRequest) {
      set_number_porting(true);
      phoneData.number_porting = true;
      setPhoneData(phoneData);
    }
  }, []);
  const listener = formListener(phoneData, setPhoneData);
  React.useEffect(() => {
    props.onChange(phoneData);
  }, [phoneData]);
  return (
    <>
      <Form.Input
        fluid
        label="Current provider"
        required
        onChange={listener.handleInputChange('provider.name')}
      />
      <Form.Input
        fluid
        label="Current provider account name"
        required
        onChange={listener.handleInputChange('provider.account_name')}
      />
      <Form.Input
        fluid
        label="Current provider account number"
        required
        onChange={listener.handleInputChange('provider.account_number')}
      />
      <Form.Select
        defaultValue={phoneData.service}
        label="Phone plan"
        options={plans}
        onChange={listener.handleSelectChange('service')}
      />
      {!isPortingRequest && (
        <Form.Checkbox
          defaultChecked={phoneData.number_porting}
          onChange={listener.handleCheckBoxChange('number_porting', e =>
            set_number_porting(e),
          )}
          label="Number porting"
        />
      )}
      {number_porting && (
        <>
          <Form.TextArea
            fluid
            label="Numbers to be ported"
            required
            onChange={listener.handleTextareaChange('ported_numbers')}
          />
          <Form.TextArea
            fluid
            label="Numbers to be disconnected"
            required
            onChange={listener.handleTextareaChange('disconnected_numbers')}
          />
        </>
      )}
    </>
  );
};
