import * as React from "react";
import { Form } from "semantic-ui-react";
import { DateInput as DI } from "semantic-ui-calendar-react";
import { validateFormat, FormListenerActions } from "../../pages/lib";

export type DateInput = {
  __component: "form.date-picker";
  label: string;
  name: string;
  is_required: boolean;
};

export const DateInputComponent = ({
  label,
  name,
  is_required,
  value,
  listener,
  handleValidStateUpdate
}: {
  label: string;
  name: string;
  is_required: boolean;
  value: string;
  listener: FormListenerActions;
  handleValidStateUpdate: (_: boolean) => void;
}) => {
  const [display, setDisplay] = React.useState(value);
  return (
    <DI
      name={name}
      value={display}
      animation={null}
      popupPosition="bottom right"
      iconPosition="left"
      as={Form.Input}
      label={label}
      dateFormat="DD/MM/YYYY"
      placeholder="DD/MM/YYYY"
      onChange={listener.handleDatePickerChange(name, setDisplay)}
      error={
        !validateFormat("dob", value, handleValidStateUpdate)
          ? { content: "Your date is not in the valid format" }
          : null
      }
      required={is_required}
    />
  );
};
