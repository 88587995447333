import * as React from "react";
import { validateFormat, FormListenerActions } from "../../pages/lib";
import { Form } from "semantic-ui-react";

export type EmailInput = {
  __component: "form.email-input";
  label: string;
  name: string;
  is_required: boolean;
};

export const EmailInputComponent = ({
  label,
  name,
  is_required,
  listener,
  value,
  handleValidStateUpdate
}: {
  label: string;
  name: string;
  is_required: boolean;
  listener: FormListenerActions;
  value: string;
  handleValidStateUpdate: (_: boolean) => void;
}) => {
  return (
    <Form.Input
      type="email"
      fluid
      label={label}
      required={is_required}
      placeholder="example@domain.com"
      error={
        !validateFormat("email", value, handleValidStateUpdate)
          ? { content: "Your email address is not in the valid format" }
          : null
      }
      onChange={listener.handleInputChange(name)}
    />
  );
};
