import * as React from "react";
import { Table, Button, Icon, Responsive, Card, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

export interface Plan {
  name: string;
  plan_id: number;
  plan_detail: PlanDetail[];
  is_capped: boolean;
}

export interface PlanDetail {
  name: string;
  value: string;
}

interface Props {
  plans: Plan[];
  readOnly?: boolean;
  showGst?: boolean;
  notMonthly?: boolean;
  isBusiness?: boolean;
  unRoundPrice?: boolean;
}

export default function PlansTable(props: Props) {
  const plans = props.plans;
  const readOnly = props.readOnly || false;
  const showGst = props.showGst || false;
  const isBusiness = props.isBusiness || false;
  const notMonthly = props.notMonthly || false;
  const unRoundPrice = props.unRoundPrice || false;
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  if (plans.length == 0) {
    return null;
  }
  if (isMobile) {
    return (
      <>
        {plans.map((plan, i) => (
          <Card fluid key={i}>
            <Card.Content>
              <Card.Header>{plan.name}</Card.Header>
            </Card.Content>
            <Card.Content>
              <Grid divided="vertically">
                {plan.plan_detail.map(detail => {
                  return (
                    <Grid.Row columns={2} key={detail.name}>
                      <Grid.Column>
                        <b>{detail.name}</b>
                      </Grid.Column>
                      <Grid.Column>{detail.value}</Grid.Column>
                    </Grid.Row>
                  );
                })}
                {showGst && (
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <b>Price after GST</b>
                    </Grid.Column>
                    <Grid.Column>
                      {(() => {
                        const price = plan.plan_detail.find(
                          p => p.name.toLowerCase() == "price"
                        ).value;
                        let price_extract = "";
                        if (!notMonthly) {
                          price_extract = price.match(/^\$(.*) \//)[1];
                        } else {
                          price_extract = price.match(/^\$(.*)/)[1];
                        }
                        const price_num = parseFloat(price_extract);
                        const price_after = price_num + (price_num * 15) / 100;
                        let display_price = price_after + "";
                        if (!unRoundPrice) {
                          display_price = price_after.toFixed(0);
                        }
                        return `$${display_price}${
                          !notMonthly ? " / month" : ""
                        }`;
                      })()}
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Card.Content>
            {!readOnly && (
              <Card.Content extra textAlign="center">
                <Button
                  animated="vertical"
                  as={Link}
                  to={`/select-plan/${plan.plan_id}/${
                    isBusiness === true ? "business" : ""
                  }`}
                  negative
                >
                  <Button.Content visible>Select plan</Button.Content>

                  <Button.Content hidden>
                    <Icon name="shop" />
                  </Button.Content>
                </Button>
              </Card.Content>
            )}
          </Card>
        ))}
      </>
    );
  }
  return (
    <Table celled definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          {plans.map(plan => (
            <Table.HeaderCell key={plan.name} textAlign="center">
              {plan.name}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {plans[0].plan_detail.map((d, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell>{d.name}</Table.Cell>
              {plans.map((plan, j) => (
                <Table.Cell key={j} textAlign="center">
                  {plan.plan_detail[i].value}
                </Table.Cell>
              ))}
            </Table.Row>
          );
        })}
        {showGst && (
          <Table.Row>
            <Table.Cell>Price after GST</Table.Cell>
            {plans.map((plan, i) => {
              let priceData = plan.plan_detail.find(d => {
                return d.name.toLowerCase().trim() == "price";
              });

              if (!priceData) {
                return (
                  <Table.Cell textAlign="center" key={i}>
                    Price is missing
                  </Table.Cell>
                );
              }

              let price = priceData.value;

              let price_extract = "";
              if (!notMonthly) {
                price_extract = price.match(/^\$(.*) \//)[1];
              } else {
                price_extract = price.match(/^\$(.*)/)[1];
              }
              const price_num = parseFloat(price_extract);
              const price_after = price_num + (price_num * 15) / 100;
              let display_price = price_after + "";
              if (!unRoundPrice) {
                display_price = price_after.toFixed(0);
              }
              return (
                <Table.Cell textAlign="center" key={i}>
                  ${display_price}
                  {!notMonthly && " / month"}
                </Table.Cell>
              );
            })}
          </Table.Row>
        )}
        {!readOnly && (
          <Table.Row>
            <Table.Cell />
            {plans.map((plan, i) => (
              <Table.Cell textAlign="center" key={i}>
                <Button
                  animated="vertical"
                  as={Link}
                  to={`/select-plan/${plan.plan_id}/${
                    isBusiness === true ? "business" : ""
                  }`}
                  negative
                >
                  <Button.Content visible>Select plan</Button.Content>

                  <Button.Content hidden>
                    <Icon name="shop" />
                  </Button.Content>
                </Button>
              </Table.Cell>
            ))}
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}
