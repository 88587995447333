import * as React from 'react';

interface Point {
  x: number;
  y: number;
}

function SignArea(_, ref) {
  const canvas = React.useRef<HTMLCanvasElement>(null);
  let context: CanvasRenderingContext2D;
  React.useEffect(() => {
    let isMouseDown = false;
    let path: Point[] = [];
    context = canvas.current.getContext('2d');
    canvas.current.onmousedown = _ => {
      isMouseDown = true;
    };
    canvas.current.ontouchstart = _ => {
      isMouseDown = true;
    };
    window.ontouchend = window.onmouseup = _ => {
      isMouseDown = false;
      path = [];
    };

    canvas.current.onmousemove = (e: MouseEvent) => {
      const rect = canvas.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      if (isMouseDown) {
        path.push({x, y});
        context.beginPath();
        context.lineWidth = 5;
        context.strokeStyle = 'black';
        context.moveTo(path[0].x, path[0].y);
        for (const point of path) {
          context.lineTo(point.x, point.y);
        }
        context.stroke();
        context.closePath();
      }
    };
    canvas.current.ontouchmove = (e: TouchEvent) => {
      const rect = canvas.current.getBoundingClientRect();
      const x = e.touches[0].clientX - rect.left;
      const y = e.touches[0].clientY - rect.top;
      if (isMouseDown) {
        path.push({x, y});
        context.beginPath();
        context.lineWidth = 5;
        context.strokeStyle = 'black';
        context.moveTo(path[0].x, path[0].y);
        for (const point of path) {
          context.lineTo(point.x, point.y);
        }
        context.stroke();
        context.closePath();
      }
    };
    canvas.current.style.width = '100%';
    canvas.current.style.height = '100%';
    canvas.current.width = canvas.current.offsetWidth;
    canvas.current.height = canvas.current.offsetHeight;
  }, []);

  React.useImperativeHandle(ref, () => ({
    clearCanvas() {
      context.clearRect(0, 0, canvas.current.width, canvas.current.height);
    },
    getCanvasContentAsBase64() {
      return canvas.current.toDataURL();
    },
  }));

  return (
    <div
      className="sign-area"
      style={{
        width: '100%',
        height: '300px',
        background: 'white',
        border: '1px solid #d3d3d3',
      }}>
      <canvas ref={canvas}></canvas>
    </div>
  );
}

export default React.forwardRef(SignArea);
