import * as React from "react";
import { Form } from "semantic-ui-react";

export type SubmitButton = {
  __component: "form.submit-button";
  label: string;
};

export const SubmitButtonComponent = ({ label }: { label: string }) => {
  return <Form.Button negative>{label}</Form.Button>;
};
