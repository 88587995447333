import * as React from "react";
import { Form } from "semantic-ui-react";
import { validateFormat, FormListenerActions } from "../../pages/lib";

export type DrivingLicenseInput = {
  __component: "form.driving-license-input";
  label_license_number: string;
  name_license_number: string;
  label_license_version: string;
  name_license_version: string;
  is_required: boolean;
};

export const DrivingLicenseInputComponent = ({
  label_license_number,
  name_license_number,
  label_license_version,
  name_license_version,
  is_required,
  listener,
  license_number,
  license_version,
  handleValidStateUpdate
}: {
  label_license_number: string;
  name_license_number: string;
  label_license_version: string;
  name_license_version: string;
  is_required: boolean;
  listener: FormListenerActions;
  license_number: string;
  license_version: string;
  handleValidStateUpdate: (_: boolean) => void;
}) => {
  return (
    <Form.Group>
      <Form.Input
        fluid
        label={label_license_number}
        width={10}
        maxLength={8}
        required={is_required}
        error={
          !validateFormat(
            "driving_license_number",
            license_number,
            handleValidStateUpdate
          )
            ? {
                content:
                  "Your driving license number is not in the valid format"
              }
            : null
        }
        onChange={listener.handleInputChange(name_license_number)}
      />
      <Form.Input
        fluid
        label={label_license_version}
        width={6}
        maxLength={3}
        required={is_required}
        error={
          !validateFormat(
            "driving_license_version",
            license_version,
            handleValidStateUpdate
          )
            ? {
                content: "Your mobile number is not in the valid format"
              }
            : null
        }
        onChange={listener.handleInputChange(name_license_version)}
      />
    </Form.Group>
  );
};
