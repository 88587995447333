import * as React from "react";
import { RichText, RichTextComponent } from "./rich-text";
import { Image, ImageComponent } from "./image";
import { PlansTable, PlansTableComponent } from "./plans-table";
import { IFrame, IFrameComponent } from "./iframe";
import { Collapsible, CollapsibleComponent } from "./collapsible";

import { TextInput, TextInputComponent } from "./text-input";
import { NumberInput, NumberInputComponent } from "./number-input";
import { DateInput, DateInputComponent } from "./date-input";
import { AddressInput, AddressInputComponent } from "./address-input";
import { PhoneInput, PhoneInputComponent } from "./phone-input";
import { EmailInput, EmailInputComponent } from "./email-input";
import { PasswordInput, PasswordInputComponent } from "./password-input";
import { LongTextInput, LongTextInputComponent } from "./long-text-input";
import { CheckBox, CheckBoxComponent } from "./check-box";
import { DropdownSelect, DropdownSelectComponent } from "./dropdown-select";
import { SubmitButton, SubmitButtonComponent } from "./submit-button";
import {
  DrivingLicenseInput,
  DrivingLicenseInputComponent
} from "./driving-license-input";
import { FormListenerActions } from "../../pages/lib";
import {
  MultipleChoiceQuestion,
  MultipleChoiceQuestionComp
} from "./multiple-choice";
import {
  MultipleChoiceWithPictureQuestion,
  MultipleChoiceWithPictureComp
} from "./multiple-choice-with-picture";
import {
  MultipleChoiceQuestionFOP,
  MultipleChoiceQuestionFOPComp
} from "./multiple-choice-fop";
import {
  MultipleChoiceWithPictureQuestionFOP,
  MultipleChoiceWithPictureCompFOP
} from "./multiple-choice-with-picture-fop";
import { PlansContent, PlansContentComp } from "./plans-content";
import { ContactContent, ContactContentComp } from "./contact-content";

export type StartNestedForm = {
  __component: "form.start-sub-form";
  show_when_name_is_true: string;
};

export type EndNestedForm = {
  __component: "form.end-sub-form";
};

export type Content = RichText | Image | PlansTable | IFrame | Collapsible;
export type FormContent =
  | Content
  | TextInput
  | DateInput
  | AddressInput
  | PhoneInput
  | EmailInput
  | DrivingLicenseInput
  | CheckBox
  | PasswordInput
  | LongTextInput
  | DropdownSelect
  | StartNestedForm
  | EndNestedForm
  | SubmitButton
  | NumberInput;

export type PreSelectPlanContent =
  | Content
  | MultipleChoiceQuestion
  | MultipleChoiceWithPictureQuestion;

export type FindOutPlanContent =
  | Content
  | MultipleChoiceQuestionFOP
  | MultipleChoiceWithPictureQuestionFOP
  | PlansContent
  | ContactContent;

export function renderContent(content: Content[]) {
  return content.map((component, index) => {
    switch (component.__component) {
      case "pages.rich-text":
        return <RichTextComponent key={index} content={component.content} />;
      case "pages.image":
        return <ImageComponent key={index} image_url={component.image.url} />;
      case "services.plan-table":
        return <PlansTableComponent key={index} comp={component} />;
      case "pages.iframe":
        return <IFrameComponent key={index} comp={component} />;
      case "pages.collapsible":
        return <CollapsibleComponent key={index} {...component} />;
    }
  });
}

export function renderFindOutPlanContent(
  content: FindOutPlanContent,
  renderable: string[],
  setJump: (location: string, index: number) => void
) {
  switch (content.__component) {
    case "find-out-plan.multiple-choice":
      if (!renderable.includes(content.name)) {
        return null;
      }
      return <MultipleChoiceQuestionFOPComp {...content} setJump={setJump} />;
    case "find-out-plan.multiple-choice-with-picture":
      if (!renderable.includes(content.name)) {
        return null;
      }
      return (
        <MultipleChoiceWithPictureCompFOP {...content} setJump={setJump} />
      );
    case "find-out-plan.plans-content":
      if (!renderable.includes(content.name)) {
        return null;
      }
      return <PlansContentComp {...content} />;
    case "find-out-plan.contact-content":
      if (!renderable.includes(content.name)) {
        return null;
      }
      return <ContactContentComp {...content} />;
    default:
      return renderContent([content]);
  }
}

export function renderPreSelectPlanContent(
  content: PreSelectPlanContent,
  index: number,
  rightFormCallback: (_: boolean) => void,
  nextQuestionCallback: (_: boolean) => void
) {
  switch (content.__component) {
    case "pre-select-plan.multiple-choices":
      return (
        <MultipleChoiceQuestionComp
          rightFormCallback={rightFormCallback}
          nextQuestionCallback={nextQuestionCallback}
          {...content}
          index={index}
        />
      );
    case "pre-select-plan.multiple-choice-with-image":
      return (
        <MultipleChoiceWithPictureComp
          {...content}
          rightFormCallback={rightFormCallback}
          nextQuestionCallback={nextQuestionCallback}
          index={index}
        />
      );
    default:
      return renderContent([content]);
  }
}

export function renderFormContent(
  content: FormContent,
  formData: any,
  handleValidStateUpdate: (_: boolean) => void,
  listener: FormListenerActions
) {
  switch (content.__component) {
    case "form.text-input":
      return (
        <TextInputComponent
          key={content.name}
          {...content}
          listener={listener}
        />
      );
    case "form.date-picker":
      return (
        <DateInputComponent
          {...content}
          value={formData[content.name] || ""}
          key={content.name}
          handleValidStateUpdate={handleValidStateUpdate}
          listener={listener}
        />
      );
    case "form.address-input":
      return (
        <AddressInputComponent
          key={content.name}
          {...content}
          listener={listener}
        />
      );
    case "form.phone-input":
      return (
        <PhoneInputComponent
          {...content}
          key={content.name}
          listener={listener}
          value={formData[content.name] || ""}
          handleValidStateUpdate={handleValidStateUpdate}
        />
      );
    case "form.email-input":
      return (
        <EmailInputComponent
          {...content}
          key={content.name}
          listener={listener}
          value={formData[content.name] || ""}
          handleValidStateUpdate={handleValidStateUpdate}
        />
      );
    case "form.driving-license-input":
      return (
        <DrivingLicenseInputComponent
          {...content}
          key={content.name_license_number}
          listener={listener}
          license_version={formData[content.name_license_version] || ""}
          license_number={formData[content.name_license_number] || ""}
          handleValidStateUpdate={handleValidStateUpdate}
        />
      );
    case "form.check-box":
      return (
        <CheckBoxComponent
          key={content.name}
          {...content}
          listener={listener}
        />
      );
    case "form.password-input":
      return (
        <PasswordInputComponent
          key={content.name}
          {...content}
          listener={listener}
        />
      );
    case "form.long-text-input":
      return (
        <LongTextInputComponent
          key={content.name}
          {...content}
          listener={listener}
        />
      );
    case "form.dropdown-select":
      return (
        <DropdownSelectComponent
          key={content.name}
          {...content}
          listener={listener}
        />
      );
    case "form.number-input":
      return (
        <NumberInputComponent
          key={content.name}
          {...content}
          listener={listener}
        />
      );
    case "form.submit-button":
      return <SubmitButtonComponent {...content} />;
    case "form.start-sub-form":
    case "form.end-sub-form":
      return null;
    default:
      return renderContent([content]);
  }
}
