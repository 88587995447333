import * as React from "react";
import { Button, Responsive } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default () => {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  return (
    <div className="403-page" style={{ textAlign: "center" }}>
      <h1 style={{ fontSize: "200px" }}>403</h1>
      <h1>Oops! We can&apos;t let you see this page!</h1>
      <p>Are you lost? Let&apos;s go back to the home page!</p>
      <Button color="red" as={Link} to="/">
        Go home
      </Button>
    </div>
  );
};
