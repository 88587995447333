import * as React from "react";
import { useParams, Redirect } from "react-router-dom";
import {
  Grid,
  Card,
  Modal,
  Button,
  Icon,
  Step,
  Responsive,
} from "semantic-ui-react";
import SignArea from "../../components/sign-area";
import Preloader from "../../components/preloader";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import Error404 from "../errors/404";
import PreSelectPlan from "../pre-select-plan";
import { CMSAPI, Plan, Service } from "../../lib/cms-api";

import DirectPaymentForm from "./components/direct-payment-form";
// // business
// import PersonalInformationFormBusinessFibre from "./components/business/fibre-dsl/personal-information-form";

// // rural
// import PersonalInformationFormRural from "./components/rural/personal-information-form";

// // residential
// import PersonalInformationFormResidentialFibre from "./components/residential/fibre-dsl/personal-information-form";

// import PersonalInformationFormResidentialFibrePrivateSubdivision from "./components/residential/fibre-private-subdivisions/personal-information-form";

// // rbi
// import PersonalInformationFormRBI from "./components/rbi/personal-information-form";

// // grey power
// import PersonalInformationFormGreyPower from "./components/greypower/personal-information-form";

// // lte
// import PersonalInformationFormLTE from "./components/lte/personal-information-form";
import { CMSContractForm } from "../../components/cms-contract-form";

interface Params {
  planId?: string;
  isBusiness?: string;
}

const allowPreSelect = false;

const steps = [
  {
    icon: "user",
    title: "Personal Information",
    description: "Let us know who you are",
  },
  {
    icon: "payment",
    title: "Billing",
    description: "Enter billing information",
  },
];

const cms = new CMSAPI();

export default function SelectPlan() {
  const params: Params = useParams();
  const planId = parseInt(params.planId) || -1;
  if (planId < 0) {
    return <Error404 />;
  }
  const [isErrorFromServer, setIsErrorFromServer] = React.useState(false);
  const [errorFromServer, setErrorFromServer] = React.useState("");
  const [isShowSignArea, setShowSignArea] = React.useState(false);
  const [isRedirectToHome, setIsRedirectToHome] = React.useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [smallestInvalidStep, setSmallestInvalidStep] = React.useState(0);
  const [maxVisitStep, setMaxVisitStep] = React.useState(0);
  const [stepData, setStepData] = React.useState([]);
  const [isRightForm, setIsRightForm] = React.useState(false);
  const [isHasDevice, setIsHasDevice] = React.useState(false);
  const [planData, setPlanData] = React.useState<Plan>(null);
  const [service, setService] = React.useState<Service>(null);
  const [planNotFound, setPlanNotFound] = React.useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  React.useEffect(() => {
    cms
      .fetchPlan(planId)
      .then((data) => {
        const [plan, service] = data;
        setPlanData(plan);
        setService(service);
        if (plan.skip_pre_select_plan) {
          setIsRightForm(true);
        }
      })
      .catch(() => setPlanNotFound(true));
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep, isRightForm]);

  const PlanDetails = () => {
    React.useLayoutEffect(() => {
      if (!isMobile) {
        const card = document.getElementById("plan-details-card");
        if (!card) {
          return;
        }
        const y = card.clientTop + 100;
        const width = card.offsetWidth;
        const stickPlanCard = () => {
          if (window.scrollY > y) {
            card.style.position = "fixed";
            card.style.width = `${width}px`;
            card.style.top = `130px`;
          } else {
            card.style.position = "";
            card.style.width = "";
            card.style.top = "";
          }
        };
        window.addEventListener("scroll", stickPlanCard);
        return () => {
          window.removeEventListener("scroll", stickPlanCard);
        };
      }
    }, []);
    return (
      <>
        <h3>Plan details</h3>
        <Card fluid id="plan-details-card">
          <Card.Content>
            <Card.Header>{planData.name}</Card.Header>
            <Card.Description>
              <ul>
                {planData.plan_detail
                  .filter((k) => !["id", "name"].includes(k.name))
                  .map((key, i) => (
                    <li key={i}>
                      {key.name}: {key.value}
                    </li>
                  ))}
              </ul>
            </Card.Description>
          </Card.Content>
        </Card>
      </>
    );
  };

  // Filter data that has been ignored in the CMS
  const filter_ignore_data = (data: object) => {
    const result = {};
    for (const key in data) {
      if (data.hasOwnProperty(key) && !key.endsWith("__ignore")) {
        result[key] = data[key];
      }
    }
    return result;
  };

  const handlePrintPDF = () => {
    const formData = filter_ignore_data(stepData[0]);
    const billing = stepData[1];
    const signature = getSignature();
    const json_data = JSON.stringify({
      form_data: formData,
      billing: billing,
      plan: planId,
      signature: signature,
      service: service.name || service.display_name,
      plan_name: planData.name,
      is_business: service.is_business_service,
    });
    const url =
      process.env.NODE_ENV === "production"
        ? "/api/contract/save?use_new=true"
        : "http://192.168.16.96:8000/api/contract/save?use_new=true";
    fetch(url, {
      method: "POST",
      body: json_data,
    })
      .then((res) => res.json())
      .then((response) => {
        setIsSubmitLoading(false);
        if (response.status == "success") {
          setIsSubmitSuccess(true);
        } else {
          setIsErrorFromServer(true);
          setErrorFromServer(response.message);
        }
      });
  };

  const signAreaRef = React.useRef();
  const handleFormSubmit = (data: any) => {
    // setShowSignArea(true);
    stepData[activeStep] = data;
    setStepData(stepData);
    let nextStep = activeStep + 1;
    nextStep = nextStep < 1 ? nextStep : 1;
    setActiveStep(nextStep);
    if (maxVisitStep < nextStep) {
      setMaxVisitStep(nextStep);
    }
    if (!invalidStepCounts[nextStep]) {
      invalidStepCounts[nextStep] = 1;
      setInvalidStepCounts(invalidStepCounts);
      handleValidStateUpdate(nextStep)(false);
    }
    if (activeStep == 1) {
      setShowSignArea(true);
    }
  };

  const getSignature = () => {
    // @ts-ignore
    return signAreaRef.current.getCanvasContentAsBase64();
  };

  const clearSignArea = () => {
    if (signAreaRef && signAreaRef.current) {
      // @ts-ignore
      signAreaRef.current.clearCanvas();
    }
  };

  const backToHomePage = () => {
    setIsSubmitSuccess(false);
    setIsRedirectToHome(true);
  };

  const [invalidStepCounts, setInvalidStepCounts] = React.useState([]);

  const handleValidStateUpdate = (step: number) => (isValid: boolean) => {
    if (!isValid) {
      invalidStepCounts[step]++;
      setInvalidStepCounts(invalidStepCounts);
    } else {
      invalidStepCounts[step]--;
      setInvalidStepCounts(invalidStepCounts);
    }

    let smallest_step = -1;
    for (let i = 0; i < invalidStepCounts.length; i++) {
      if (invalidStepCounts[i] > 0) {
        if (smallest_step == -1 || smallest_step < i) {
          smallest_step = i;
        }
      }
    }
    if (smallest_step == -1) {
      smallest_step = maxVisitStep;
    }
    setSmallestInvalidStep(smallest_step);
  };

  const renderPersonalInformationForm = (step: number) => {
    return (
      <CMSContractForm
        template_id={service.template_id}
        show={step == 0}
        handleFormSubmit={handleFormSubmit}
        handleValidStateUpdate={handleValidStateUpdate(0)}
      />
    );
    // if (service.template_id == TemplateType.BusinessFibreAndDSL) {
    //   return (
    //     <PersonalInformationFormBusinessFibre
    //       show={step == 0}
    //       handleFormSubmit={handleFormSubmit}
    //       handleValidStateUpdate={handleValidStateUpdate(0)}
    //     />
    //   );
    // } else if (service.template_id == TemplateType.Rural) {
    //   return (
    //     <PersonalInformationFormRural
    //       is_business={service.is_business_service}
    //       has_device={isHasDevice}
    //       show={step == 0}
    //       handleFormSubmit={handleFormSubmit}
    //       handleValidStateUpdate={handleValidStateUpdate(0)}
    //     />
    //   );
    // } else if (service.template_id == TemplateType.ResidentialFibreAndDSL) {
    //   return (
    //     <PersonalInformationFormResidentialFibre
    //       show={step == 0}
    //       handleFormSubmit={handleFormSubmit}
    //       handleValidStateUpdate={handleValidStateUpdate(0)}
    //     />
    //   );
    // } else if (service.template_id == TemplateType.RBI) {
    //   return (
    //     <PersonalInformationFormRBI
    //       show={step == 0}
    //       handleFormSubmit={handleFormSubmit}
    //       handleValidStateUpdate={handleValidStateUpdate(0)}
    //       isBusiness={params.isBusiness}
    //       hasDevice={isHasDevice}
    //     />
    //   );
    // } else if (service.template_id == TemplateType.GreyPower) {
    //   return (
    //     <PersonalInformationFormGreyPower
    //       show={step == 0}
    //       handleFormSubmit={handleFormSubmit}
    //       handleValidStateUpdate={handleValidStateUpdate(0)}
    //     />
    //   );
    // } else if (service.template_id == TemplateType.LTE) {
    //   return (
    //     <PersonalInformationFormLTE
    //       show={step == 0}
    //       handleFormSubmit={handleFormSubmit}
    //       handleValidStateUpdate={handleValidStateUpdate(0)}
    //     />
    //   );
    // } else if (
    //   service.template_id == TemplateType.ResidentialFibrePrivateSubdivision
    // ) {
    //   return (
    //     <PersonalInformationFormResidentialFibrePrivateSubdivision
    //       show={step == 0}
    //       handleFormSubmit={handleFormSubmit}
    //       handleValidStateUpdate={handleValidStateUpdate(0)}
    //     />
    //   );
    // }
  };

  const renderStep = (step: number) => {
    return (
      <>
        {renderPersonalInformationForm(step)}
        <DirectPaymentForm
          handleValidStateUpdate={handleValidStateUpdate(1)}
          handleFormSubmit={handleFormSubmit}
          show={step == 1}
        />
      </>
    );
  };

  if (isRedirectToHome) {
    return <Redirect to="/" />;
  }

  const isMobile = screen.width < Responsive.onlyComputer.minWidth;

  if (planNotFound) {
    return <Error404 />;
  }

  if (!service) {
    return <Preloader />;
  }

  if (!isRightForm && allowPreSelect) {
    return (
      <PreSelectPlan
        plan={planData}
        service={service}
        rightFormCallback={(hasDevice: boolean) => {
          setIsRightForm(true);
          setIsHasDevice(hasDevice);
        }}
      />
    );
  }

  return (
    <div className="select-plan-form">
      <Helmet>
        <title>Select plan {planData.name}</title>
      </Helmet>
      <Modal open={isShowSignArea} onClose={() => setShowSignArea(false)}>
        <Modal.Header>Please sign here</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "1.1em" }}>
            Please sign in the box bellow using your mouse or finger if you are
            on mobile
          </p>
          <SignArea ref={signAreaRef} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowSignArea(false)}>Back</Button>
          <Button onClick={clearSignArea} color="yellow">
            Clear
          </Button>
          <Button primary loading={isSubmitLoading} onClick={handlePrintPDF}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={isErrorFromServer}
        onClose={() => setIsErrorFromServer(false)}
      >
        <Modal.Header>Contract submitting error</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "1.1em" }}>
            The server responded with a error status and message:
          </p>
          <pre style={{ border: "1px solid #d3d3d3" }}>{errorFromServer}</pre>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsErrorFromServer(false)} primary>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={isSubmitSuccess}>
        <Modal.Header>Contract submitted successfully!</Modal.Header>
        <Modal.Content>
          <p style={{ textAlign: "center" }}>
            <Icon name="check circle outline" size="massive" color="green" />
          </p>
          <h2 style={{ textAlign: "center" }}>
            We have received your request for our service
          </h2>
          <p
            style={{
              fontSize: "1.1em",
              lineHeight: "1.8em",
              textAlign: "center",
            }}
          >
            We will contact you as soon as possible for more information. If you
            have any question, feel free to{" "}
            <HashLink to="/#contact-us">contact us.</HashLink>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={backToHomePage} negative>
            Back to homepage
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid>
        <Grid.Row>
          {isMobile && (
            <Grid.Column width={16} style={{ marginBottom: "50px" }}>
              <PlanDetails />
            </Grid.Column>
          )}
          <Grid.Column width={isMobile ? 16 : 10}>
            {!allowPreSelect && (
              <p style={{ color: "#DB2828", fontSize: "30px" }}>
                Please contact us for more information before filling out this
                form to discuss if this is the right service for you.
              </p>
            )}
            {!isMobile && (
              <Step.Group>
                {steps.map((step, i) => (
                  <Step
                    key={i}
                    icon={step.icon}
                    title={step.title}
                    description={step.description}
                    link
                    negative
                    disabled={smallestInvalidStep < i}
                    active={activeStep == i}
                    onClick={() => setActiveStep(i)}
                  />
                ))}
              </Step.Group>
            )}
            {renderStep(activeStep)}
          </Grid.Column>
          {!isMobile && (
            <Grid.Column width={6}>
              <PlanDetails />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
}
