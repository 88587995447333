import * as React from "react";
import { Form, Modal, Button, Icon } from "semantic-ui-react";
import { formListener, validateFormat } from "../lib";
import Phone, { PhoneData } from "../select-plan/components/addon/phone";
import SignArea from "../../components/sign-area";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export interface PersonalFormData {
  client_details: {
    fullname: string;
    address: string;
    phone: string;
    mobile: string;
    email: string;
  };
  invoice_email: string;
  phone_plan?: PhoneData;
}
interface Params {
  phoneType?: string;
}
export default () => {
  const params: Params = useParams();
  const [formData, setFormData] = React.useState({
    client_details: {
      fullname: "",
      address: "",
      phone: "",
      mobile: "",
      email: "",
    },
  } as PersonalFormData);
  const [isBusiness, setIsBusiness] = React.useState(false);
  const [isErrorFromServer, setIsErrorFromServer] = React.useState(false);
  const [isShowSignArea, setShowSignArea] = React.useState(false);
  const [errorFromServer, setErrorFromServer] = React.useState("");
  const signAreaRef = React.useRef();

  const [isRedirectToHome, setIsRedirectToHome] = React.useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = React.useState(false);
  React.useEffect(() => {
    if (params.phoneType == "business") {
      setIsBusiness(true);
    } else if (params.phoneType == "rbi_business") {
      setIsBusiness(true);
    } else {
      setIsBusiness(false);
    }
  }, []);
  const phone_plans = () => {
    if (params.phoneType == "business") {
      return [
        { key: 1, text: "Business Value", value: 1 },
        { key: 2, text: "Business Go", value: 2 },
        { key: 3, text: "Mobile 100", value: 3 },
      ];
    } else if (params.phoneType == "residential") {
      return [
        { key: 1, text: "Home Basic", value: 1 },
        { key: 2, text: "Home Standard", value: 2 },
        { key: 3, text: "Home Business", value: 3 },
      ];
    }
    return [{ key: 4, text: "RBI Voice", value: 4 }];
  };
  const getSignature = () => {
    // @ts-ignore
    return signAreaRef.current.getCanvasContentAsBase64();
  };

  const clearSignArea = () => {
    if (signAreaRef && signAreaRef.current) {
      // @ts-ignore
      signAreaRef.current.clearCanvas();
    }
  };

  const [valids, setValids] = React.useState([]);
  const handleValidStateUpdate = (index: number) => (val: boolean) => {
    if (valids[index] == undefined) {
      valids[index] = false;
    } else if (valids[index] != val) {
      valids[index] = val;
      setValids(valids);
    }
  };

  const handleValidPhone = handleValidStateUpdate(0);
  const handleValidMobile = handleValidStateUpdate(1);
  const handleValidEmail = handleValidStateUpdate(2);
  const handleValidInvoiceEmail = handleValidStateUpdate(3);

  const isValidForm = () => {
    const isFormDataNotNull = () =>
      formData.client_details.fullname &&
      formData.client_details.email &&
      formData.client_details.address &&
      formData.client_details.phone &&
      formData.client_details.mobile &&
      formData.invoice_email;
    return !valids.some((t) => t == false) && isFormDataNotNull();
  };

  const handleFormSubmit = () => {
    setShowSignArea(true);
  };

  const submitForm = () => {
    const signature = getSignature();
    const json_data = JSON.stringify({
      signature,
      formData,
      isBusiness,
    });
    const url =
      process.env.NODE_ENV === "production"
        ? "/api/phone-porting/request?use_new=true"
        : "http://localhost:8000/api/phone-porting/request?use_new=true";
    fetch(url, {
      method: "POST",
      body: json_data,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status == "success") {
          setIsSubmitSuccess(true);
        } else {
          setIsErrorFromServer(true);
          setErrorFromServer(response.message);
        }
      })
      .catch(() => setIsErrorFromServer(true));
  };

  const listener = formListener(formData, setFormData);
  const backToHomePage = () => {
    setIsSubmitSuccess(false);
    setIsRedirectToHome(true);
  };
  if (isRedirectToHome) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <Modal open={isShowSignArea} onClose={() => setShowSignArea(false)}>
        <Modal.Header>Please sign here</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "1.1em" }}>
            Please sign in the box bellow using your mouse or finger if you are
            on mobile
          </p>
          <SignArea ref={signAreaRef} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowSignArea(false)}>Back</Button>
          <Button onClick={clearSignArea} negative>
            Clear
          </Button>
          <Button primary onClick={submitForm}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={isErrorFromServer}
        onClose={() => setIsErrorFromServer(false)}
      >
        <Modal.Header>Direct debit update error</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "1.1em" }}>
            There is something wrong with the server, please contact{" "}
            <HashLink to="/#contact-us">VelocityNet support</HashLink> for more
            information.
            <br />
            <b>Error:</b> {errorFromServer}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsErrorFromServer(false)} primary>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={isSubmitSuccess}>
        <Modal.Header>
          Phone porting request submitted successfully!
        </Modal.Header>
        <Modal.Content>
          <p style={{ textAlign: "center" }}>
            <Icon name="check circle outline" size="massive" color="green" />
          </p>
          <h2 style={{ textAlign: "center" }}>
            We have received your phone porting request.
          </h2>
          <p
            style={{
              fontSize: "1.1em",
              lineHeight: "1.8em",
              textAlign: "center",
            }}
          >
            We will contact you as soon as possible for more information. If you
            have any question, feel free to{" "}
            <HashLink to="/#contact-us">contact us.</HashLink>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={backToHomePage} primary>
            Back to homepage
          </Button>
        </Modal.Actions>
      </Modal>
      <h1>Request phone porting</h1>
      <Form onSubmit={handleFormSubmit}>
        <h1>Personal information</h1>
        <h3>Client Details</h3>
        <Form.Input
          fluid
          label="Full name"
          required
          onChange={listener.handleInputChange("client_details.fullname")}
        />
        <Form.TextArea
          label="Address"
          required
          onChange={listener.handleTextareaChange("client_details.address")}
        />
        <Form.Input
          type="tel"
          fluid
          label="Phone"
          required
          error={
            !validateFormat(
              "phone",
              formData.client_details.phone,
              handleValidPhone
            )
              ? { content: "Your phone number is not in the valid format" }
              : null
          }
          onChange={listener.handleInputChange("client_details.phone")}
        />
        <Form.Input
          type="tel"
          fluid
          label="Mobile"
          required
          error={
            !validateFormat(
              "phone",
              formData.client_details.mobile,
              handleValidMobile
            )
              ? { content: "Your mobile number is not in the valid format" }
              : null
          }
          onChange={listener.handleInputChange("client_details.mobile")}
        />
        <Form.Input
          type="email"
          fluid
          label="Email"
          required
          error={
            !validateFormat(
              "email",
              formData.client_details.email,
              handleValidEmail
            )
              ? { content: "Your email address is not in the valid format" }
              : null
          }
          onChange={listener.handleInputChange("client_details.email")}
        />
        <h1>Invoices by email</h1>
        <Form.Input
          label="Email address"
          type="email"
          fluid
          required
          error={
            !validateFormat(
              "email",
              formData.invoice_email,
              handleValidInvoiceEmail
            )
              ? { content: "Your email address is not in the valid format" }
              : null
          }
          onChange={listener.handleInputChange("invoice_email")}
        />
        <h1>Phone plan</h1>
        <Phone
          isPortingRequest={true}
          plans={phone_plans()}
          onChange={(data) => {
            formData.phone_plan = data;
            setFormData(formData);
          }}
        />
        <Form.Button disabled={!isValidForm()} primary>
          Submit
        </Form.Button>
      </Form>
    </>
  );
};
