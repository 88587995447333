import * as React from "react";
import { Card, Grid, Responsive } from "semantic-ui-react";
import {
  CMSAPI,
  Service,
  Plan,
  PreSelectPlanFormData
} from "../../lib/cms-api";
import { renderPreSelectPlanContent } from "../../components/cms";
import Preloader from "../../components/preloader";

interface Props {
  service: Service;
  plan: Plan;
  rightFormCallback: (hasDevice: boolean) => void;
}

const cms = new CMSAPI();

export default ({ rightFormCallback, plan, service }: Props) => {
  const [formContent, setFormContent] = React.useState<PreSelectPlanFormData>(
    null
  );

  const [maxIndex, setMaxIndex] = React.useState(0);

  const nextQuestionCallback = (index: number) => (canGoNext: boolean) => {
    if (!canGoNext) {
      setMaxIndex(index);
    } else {
      setMaxIndex(index + 1);
    }
  };

  React.useEffect(() => {
    cms.fetchPreSelectPlanForm(service.id).then(preSelectForm => {
      if (!preSelectForm) {
        return rightFormCallback(true);
      }
      setFormContent(preSelectForm);
    });
  }, []);

  const renderPlanDetails = () => (
    <>
      <h3>Plan details</h3>
      <Card fluid id="plan-details-card">
        <Card.Content>
          <Card.Header>{plan.name}</Card.Header>
          <Card.Description>
            <ul>
              {plan.plan_detail
                .filter(k => !["id", "name"].includes(k.name))
                .map((key, i) => {
                  return (
                    <li key={i}>
                      {key.name}: {key.value}
                    </li>
                  );
                })}
            </ul>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );

  const renderQuestions = () => {
    return formContent.content.map((data, index) => {
      if (index > maxIndex) {
        return null;
      }
      return renderPreSelectPlanContent(
        data,
        index,
        rightFormCallback,
        nextQuestionCallback(index)
      );
    });
  };

  const isMobile = screen.width < Responsive.onlyComputer.minWidth;

  if (!formContent) {
    return <Preloader />;
  }

  return (
    <div className="pre-select-plan-page">
      <h1>Is this plan right for you</h1>
      <Grid>
        <Grid.Row>
          {isMobile && (
            <Grid.Column width={16}>
              {renderPlanDetails()}
              <br />
            </Grid.Column>
          )}
          <Grid.Column width={isMobile ? 16 : 10}>
            {renderQuestions()}
          </Grid.Column>
          {!isMobile && (
            <Grid.Column width={6}>{renderPlanDetails()}</Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
};
