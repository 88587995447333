import * as React from 'react';
import {Form} from 'semantic-ui-react';

interface PhoneInputProps {
  label: string;
  required?: boolean;
  error: {content?: string};
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: {value: string},
  ) => void;
}

export default ({required, label, error, onChange}: PhoneInputProps) => {
  const [value, setValue] = React.useState('');
  const formatPhone = (phone: string) => {
    // See: https://en.wikipedia.org/wiki/Telephone_numbers_in_New_Zealand
    /*
     * Phone format:
     * (0x) xxx xxxx  # landline
     * (0xx) xxx xxxx # mobile phone
     * (0xxx) xxx xxx # mobile phone
     * */
    const cleanPhone = deformatPhone(phone);

    if (cleanPhone.length == 9) {
      const prefix = cleanPhone.substr(0, 2);
      const mid = cleanPhone.substr(2, 3);
      const end = cleanPhone.substr(5);
      return `(${prefix}) ${mid} ${end}`;
    }

    // 10 digits has 2 cases
    // (0xx) prefix
    // (0xxx) prefix
    //
    // (0xx) includes: 021, 022, 026, 027, 028, 029
    // (0xxx) includes: 0201, 0202, 0203, 0204, 0205, 0206
    // => (0xx) = (02x)
    // => (0xxx) = (020x)
    if (/^020\d/.test(cleanPhone.substr(0, 4))) {
      // => 4 digits prefix
      const prefix = cleanPhone.substr(0, 4);
      const remain = cleanPhone.substr(4);
      if (remain.length == 6 || remain.length == 7) {
        const mid = remain.substr(0, 3);
        const end = remain.substr(3);
        return `(${prefix}) ${mid} ${end}`;
      }
    } else if (/^02[0-9]/.test(cleanPhone.substr(0, 3))) {
      // => 3 digits prefix
      const prefix = cleanPhone.substr(0, 3);
      const remain = cleanPhone.substr(3);
      if (remain.length >= 6 && remain.length <= 8) {
        const mid = remain.substr(0, 3);
        const end = remain.substr(3);
        return `(${prefix}) ${mid} ${end}`;
      }
    }
    return cleanPhone;
  };

  const deformatPhone = (phone: string) => {
    return phone.replace(/\D/g, '');
  };
  return (
    <Form.Input
      type="tel"
      fluid
      label={label}
      required={required}
      error={error}
      value={value}
      onChange={(e, {value}) => {
        setValue(formatPhone(value));
        onChange(e, {value: deformatPhone(value)});
      }}
      placeholder="Phone number starts with 0"
    />
  );
};
