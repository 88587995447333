import * as React from "react";
import {
  Button,
  Grid,
  Card,
  Image,
  Responsive,
  Divider
} from "semantic-ui-react";
import { RichTextComponent } from "./rich-text";
import Lightbox from "react-image-lightbox";
import Config from "../../lib/api-config";
import { cleanURL } from "../../lib/util";
import ContactInfo from "../../components/contact-info";
import LeaveInfoForm from "../../components/leave-info-form";

export type MultipleChoiceWithPictureQuestion = {
  __component: "pre-select-plan.multiple-choice-with-image";
  question: string;
  ChoiceWithImage: Choice[];
};

export type Choice = {
  label: string;
  image: { url: string };
  content_to_show: string;
  show_contact_form: boolean;
  is_end_form: boolean;
  allow_to_pass: boolean;
};

export const MultipleChoiceWithPictureComp = ({
  index,
  question,
  ChoiceWithImage,
  rightFormCallback,
  nextQuestionCallback
}: {
  index: number;
  question: string;
  ChoiceWithImage: Choice[];
  rightFormCallback: (_: boolean) => void;
  nextQuestionCallback: (_: boolean) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [contentToShow, setContentToShow] = React.useState(null);
  const [showNextButton, setShowNextButton] = React.useState(false);
  const [showContactForm, setShowContactForm] = React.useState(false);
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  const [isShowPicture, setShowPicture] = React.useState(false);
  const [pictureIndex, setPictureIndex] = React.useState(0);

  const openImage = (index: number) => {
    setPictureIndex(index);
    setShowPicture(true);
  };

  React.useEffect(() => {
    let choice = ChoiceWithImage[selectedIndex];

    if (!choice) {
      return;
    }

    if (choice.is_end_form) {
      nextQuestionCallback(false);
    } else {
      nextQuestionCallback(true);
    }

    setShowContactForm(choice.show_contact_form || false);
    setContentToShow(choice.content_to_show);
    setShowNextButton(choice.allow_to_pass || false);
  }, [selectedIndex]);

  const choices = ChoiceWithImage.map((choice, idx) => {
    const selected = selectedIndex === idx;
    return (
      <Grid.Column key={idx}>
        <Card fluid style={{ marginBottom: "30px" }}>
          <Card.Content>
            <Image
              src={cleanURL(Config.CMS.url + "/" + choice.image.url)}
              wrapped
              width="100%"
              onClick={() => openImage(idx)}
              ui={false}
            />
            <br />
            <Card.Header textAlign="center">{choice.label}</Card.Header>
          </Card.Content>
          <Card.Content extra textAlign="center">
            <Button negative={selected} onClick={() => setSelectedIndex(idx)}>
              Select
            </Button>
          </Card.Content>
        </Card>
        {isShowPicture && pictureIndex == idx && (
          <Lightbox
            mainSrc={cleanURL(
              Config.CMS.url + "/" + ChoiceWithImage[pictureIndex].image.url
            )}
            onCloseRequest={() => setShowPicture(false)}
          />
        )}
      </Grid.Column>
    );
  });

  return (
    <div>
      <h2>Question {index + 1}:</h2>
      <p>{question}</p>

      <Grid>
        <Grid.Row columns={isMobile ? 1 : 2}>{choices}</Grid.Row>
      </Grid>
      <RichTextComponent content={contentToShow} />
      {showNextButton && (
        <Button negative onClick={() => rightFormCallback(true)} primary>
          Next
        </Button>
      )}
      {showContactForm && (
        <>
          <LeaveInfoForm />
          <Divider horizontal>Or</Divider>
          <h2>Contact Us</h2>
          <ContactInfo />
        </>
      )}
    </div>
  );
};
