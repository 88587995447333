import * as React from "react";
import "./styles/app.sass";
import "react-image-lightbox/style.css";
import Nav from "./components/new/nav";
import Footer from "./components/footer";
import ScrollToTop from "./components/scroll-to-top";
import NewHome from "./pages/new-home";
import SelectPlan from "./pages/select-plan";
import PhoneFeatureCodes from "./pages/phone-feature-codes";
import IPhoneIPadEmailSetup from "./pages/ipad-iphone-email-setup";
import WhatAboutATelephone from "./pages/what-about-a-telephone";
import UpdateDirectDebit from "./pages/update-direct-debit";
import RBIVoiceCallingRates from "./pages/RBI-voice-calling-rates";
import AddressChecker from "./pages/address-checker";
import PhonePortingRequest from "./pages/phone-porting-request";
import WhatPlanCanIGet from "./pages/what-plan-can-i-get";

// errors
import Page404 from "./pages/errors/404";

// API
import { CMSAPI, PageUrl } from "./lib/cms-api";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Responsive } from "semantic-ui-react";
import ReactGA from "react-ga";

// components
import PlanPage from "./components/plan-page";
import Preloader from "./components/preloader";

const cms = new CMSAPI();
const isProduction = process.env.NODE_ENV === "production";

if (isProduction) {
  ReactGA.initialize("UA-189015635-1");
}

const GATracker = ({ children }: React.PropsWithChildren<{}>) => {
  const location = useLocation();

  React.useEffect(() => {
    if (isProduction) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location.pathname]);

  return <>{children}</>;
};

export default function App() {
  const [pageUrls, setPageUrls] = React.useState<PageUrl[]>([]);

  React.useEffect(() => {
    cms.fetchPageUrls().then((urls) => {
      setPageUrls(urls);
    });
  }, []);

  if (pageUrls.length == 0) {
    return <Preloader />;
  }

  return (
    <Router>
      <GATracker>
        <ScrollToTop />
        <div className="wrapper" style={{ overflowX: "hidden" }}>
          <Nav />
          <Switch>
            <Route exact path="/">
              <NewHome />
            </Route>
            <>
              <main
                id="main-area"
                className={`main ${
                  window.screen.width < Responsive.onlyComputer.minWidth
                    ? "mobile"
                    : ""
                }`}
              >
                <Switch>
                  {pageUrls.map((url) => (
                    <Route path={`/${url.web_url}`} key={url.web_url}>
                      <PlanPage dataUrl={url.data_url} />
                    </Route>
                  ))}

                  <Route path="/select-plan/:planId/:isBusiness?">
                    <SelectPlan />
                  </Route>
                  <Route path="/phone-feature-codes">
                    <PhoneFeatureCodes />
                  </Route>
                  <Route path="/iphone-ipad-email-setup">
                    <IPhoneIPadEmailSetup />
                  </Route>
                  <Route path="/telephone">
                    <WhatAboutATelephone />
                  </Route>
                  <Route path="/update-direct-debit">
                    <UpdateDirectDebit />
                  </Route>
                  <Route path="/rbi-voice-calling-rates">
                    <RBIVoiceCallingRates />
                  </Route>
                  <Route path="/address-checker">
                    <AddressChecker />
                  </Route>
                  <Route path="/find-out-plan">
                    <WhatPlanCanIGet />
                  </Route>
                  <Route path="/phone-porting-request/:phoneType">
                    <PhonePortingRequest />
                  </Route>
                  <Route component={Page404} />
                </Switch>
              </main>
              <Footer />
            </>
          </Switch>
        </div>
      </GATracker>
    </Router>
  );
}
