import * as React from "react";
import { ButtonGroup, Button, ButtonOr, Divider } from "semantic-ui-react";
import { RichTextComponent } from "./rich-text";
import ContactInfo from "../../components/contact-info";
import LeaveInfoForm from "../../components/leave-info-form";

export type MultipleChoiceQuestion = {
  __component: "pre-select-plan.multiple-choices";
  question: string;
  Choice: Choice[];
};

export type Choice = {
  label: string;
  content_to_show: string;
  show_contact_form: boolean;
  is_end_form: boolean;
  allow_to_pass: boolean;
};

export const MultipleChoiceQuestionComp = ({
  index,
  question,
  Choice,
  rightFormCallback,
  nextQuestionCallback
}: {
  index: number;
  question: string;
  Choice: Choice[];
  rightFormCallback: (_: boolean) => void;
  nextQuestionCallback: (_: boolean) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [contentToShow, setContentToShow] = React.useState(null);
  const [showNextButton, setShowNextButton] = React.useState(false);
  const [showContactForm, setShowContactForm] = React.useState(false);

  React.useEffect(() => {
    let choice = Choice[selectedIndex];

    if (!choice) {
      return;
    }

    if (choice.is_end_form) {
      nextQuestionCallback(false);
    } else {
      nextQuestionCallback(true);
    }

    setShowContactForm(choice.show_contact_form || false);
    setContentToShow(choice.content_to_show);
    setShowNextButton(choice.allow_to_pass || false);
  }, [selectedIndex]);

  const choices = Choice.map((choice, idx) => {
    const selected = selectedIndex === idx;
    return (
      <Button negative={selected} onClick={() => setSelectedIndex(idx)}>
        {choice.label}
      </Button>
    );
  });

  return (
    <div>
      <h2>Question {index + 1}:</h2>
      <p>{question}</p>
      <ButtonGroup>
        {choices.map((choice, index) => {
          const isLast = index == choices.length - 1;
          if (!isLast) {
            return [choice, <ButtonOr />];
          }
          return choice;
        })}
      </ButtonGroup>
      <br />
      <br />
      <RichTextComponent content={contentToShow} />
      {showNextButton && (
        <>
          <br />
          <Button negative onClick={() => rightFormCallback(true)} primary>
            Next
          </Button>
          <br />
          <br />
        </>
      )}
      {showContactForm && (
        <>
          <LeaveInfoForm />
          <Divider horizontal>Or</Divider>
          <h2>Contact Us</h2>
          <ContactInfo />
        </>
      )}
    </div>
  );
};
