import * as React from "react";
import { HashLink } from "react-router-hash-link";
import { IHomePageData } from "../index";
import config from "../../../lib/api-config";
import { cleanURL } from "../../../lib/util";

interface IProps {
  data: IHomePageData;
}

export default (props: IProps) => {
  const [isFixedNav, setFixedNav] = React.useState(false);
  const { data } = props;

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setFixedNav(true);
      } else {
        setFixedNav(false);
      }
    });
  });

  return (
    <section className="introduction">
      <div className="container">
        <div className={`text-block ${isFixedNav ? "fixed" : ""}`}>
          <div className="important-message" dangerouslySetInnerHTML={{__html: data.important_message}}></div>
          <h1>{data.title}</h1>
          <p className="short-intro">{data.subtitle}</p>
          <div className="call-to-action">
            <HashLink to="/find-out-plan" className="btn active" id="find-out">
              Find out more
            </HashLink>
            <HashLink className="btn" id="contact" to="#contact-us">
              Get in touch
            </HashLink>
          </div>
        </div>
      </div>
      <img
        src={cleanURL(config.CMS.url + data.introduction_image.url)}
        className="intro-image"
      />
      <div className="container">
        <div className="partner">
          <span className="partner-label">{data.partners.title}</span>
          <ul className="partners">
            {data.partners.partner_images.map(image => (
              <li key={image.url}>
                <img src={cleanURL(config.CMS.url + image.url)} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
