import * as React from "react";
import { Form } from "semantic-ui-react";
import { FormListenerActions } from "../../pages/lib";

export type PasswordInput = {
  __component: "form.password-input";
  label: string;
  name: string;
  is_required: boolean;
};

export const PasswordInputComponent = ({
  label,
  name,
  is_required,
  listener
}: {
  label: string;
  name: string;
  is_required: boolean;
  listener: FormListenerActions;
}) => {
  return (
    <Form.Input
      fluid
      type="password"
      label={label}
      required={is_required}
      onChange={listener.handleInputChange(name)}
    />
  );
};
