import axios, { AxiosInstance } from "axios";
import config from "./api-config";
import { FormContent, Content, PreSelectPlanContent } from "../components/cms";
import { PlansTable } from "../components/cms/plans-table";

export interface Plan {
  name: string;
  plan_detail: PlanDetail[];
  plan_id: number;
  skip_pre_select_plan: boolean;
}

export interface PlanDetail {
  name: string;
  value: string;
}

export interface Service {
  id: number;
  name: string;
  plans: Plan[];
  template_id: number;
  is_business_service: boolean;
  display_name: string;
  link: string;
}

export interface PageUrl {
  web_url: string;
  data_url: string;
}

export interface NavData {
  menus: Menu[];
  logo: { url: string };
}

export interface Menu {
  name: string;
  link: PageUrl;
  custom_link: string;
  menu_items: MenuItem[];
}

export interface MenuItem {
  name: string;
  link: PageUrl;
  custom_link: string;
}

export interface Footer {
  sections: FooterSection[];
}

export interface FooterSection {
  title: string;
  items: FooterItem[];
}

export interface FooterItem {
  text: string;
  url: string;
}

export interface FormData {
  content: FormContent[];
}

export interface PreSelectPlanFormData {
  content: PreSelectPlanContent[];
  services: Service[];
}

export class CMSAPI {
  client: AxiosInstance;
  constructor() {
    this.client = axios.create({
      baseURL: config.CMS.url
    });
  }

  fetchPlan(plan_id: number): Promise<[Plan, Service]> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/services")
        .then(res => {
          const services = res.data as Service[];
          for (const service of services) {
            for (const plan of service.plans) {
              if (plan.plan_id == plan_id) {
                return resolve([plan, service]);
              }
            }
          }
          reject();
        })
        .catch(reject);
    });
  }

  fetchServices(ids: number[]): Promise<Service[]> {
    return new Promise((resolve, reject) => {
      const params = ids.reduce((acc, curr) => acc + "&id_in=" + curr, "");
      this.client
        .get("/services?" + params.slice(1))
        .then(res => {
          return resolve(res.data);
        })
        .catch(reject);
    });
  }

  fetchNav(): Promise<NavData> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/navbar")
        .then(res => {
          return resolve(res.data);
        })
        .catch(reject);
    });
  }

  fetchFooter(): Promise<Footer> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/footer")
        .then(res => {
          return resolve(res.data);
        })
        .catch(reject);
    });
  }

  fetchForm(template_id: number): Promise<FormData> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/forms?template_id_eq=" + template_id)
        .then(res => {
          return resolve(res.data[0]);
        })
        .catch(reject);
    });
  }

  fetchPreSelectPlanForm(service_id: number): Promise<PreSelectPlanFormData> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/pre-select-plan-forms")
        .then(res => {
          return resolve(
            res.data.find((item: any) =>
              item.services.find((service: any) => service.id == service_id)
            )
          );
        })
        .catch(reject);
    });
  }

  fetchPageUrls(): Promise<PageUrl[]> {
    return new Promise((resolve, reject) => {
      this.client
        .get("/pages")
        .then(res => {
          return resolve(res.data);
        })
        .catch(reject);
    });
  }

  /// Fetch page data from CMS & populate service data if present
  /// If strapi support nested level please simplify this mess
  fetchPage<T>(url: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.client
        .get(url)
        .then(res => {
          const pageData = res.data;
          if (pageData.content) {
            const tasks = [];
            const indexes = [];
            const content = pageData.content as Content[];
            let index = 0;
            for (const component of content) {
              if (component.__component == "services.plan-table") {
                tasks.push(
                  this.client.get(`/services/${component.service.id}`)
                );
                indexes.push(index);
              }
              index += 1;
            }
            Promise.all(tasks)
              .then(resps => {
                let index = 0;
                for (const res of resps) {
                  let service_index = indexes[index];
                  let service_data = res.data;
                  let table = content[service_index] as PlansTable;
                  table.service = service_data;
                  index++;
                }
                resolve(pageData);
              })
              .catch(reject);
          } else {
            resolve(pageData);
          }
        })
        .catch(reject);
    });
  }
}
