import * as React from "react";
import { Grid, Responsive } from "semantic-ui-react";

export default () => {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  return (
    <Grid>
      <Grid.Row columns={isMobile ? 1 : 2}>
        <Grid.Column>
          <p>
            <b>Physical Address</b>
          </p>
          <p>18 Bond Street Invercargill</p>
          <p>
            <b>Support:</b> faster@velocitynet.co.nz
          </p>
          <p>
            <b>Accounts:</b> accounts@velocitynet.co.nz
          </p>
          <br />
          <p>
            <b>Business Hours</b>
          </p>
          <p>Monday to Friday</p>
          <p>8:30 am to 5:00 pm</p>
        </Grid.Column>
        <Grid.Column>
          {isMobile && <br />}
          <p>
            <b>Postal Address</b>
          </p>
          <p>Velocitynet P.O. Box 5153 Waikiwi</p>
          <p>
            <b>Support Ph:</b> 03 218 4489
          </p>
          <p>
            <b>Accounts Ph:</b> 03 215 4026
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
