import * as React from "react";

export type RichText = {
  __component: "pages.rich-text";
  content: string;
};

export const RichTextComponent = ({ content }: { content: string }) => {
  const dom = React.useRef(null);
  React.useEffect(() => {
    /// Add class & move table out of the figure tag
    /// I can't customize the ckeditor in strapi so have to do this
    /// Please clean this up if you can. Sorry for the inconvenience.
    const tables = [...dom.current.querySelectorAll("table")];
    for (const table of tables) {
      if (!table.classList.contains("table")) {
        table.classList.add("table");
      }

      if (!table.classList.contains("ui")) {
        table.classList.add("ui");
      }

      const firstCell = table.querySelector("thead > tr > th:nth-child(1)");

      if (firstCell) {
        if (firstCell.textContent.trim() == "") {
          // empty first cell, we consider this as definition
          table.classList.add("definition");
          table.classList.add("celled");

          const cells = [...table.querySelectorAll("th, td:not(:first-child)")];
          for (const cell of cells) {
            cell.classList.add("center");
            cell.classList.add("aligned");
          }
        }
      }

      if (table.parentElement.tagName == "FIGURE") {
        table.parentElement.parentElement.insertBefore(
          table,
          table.parentElement
        );
      }
    }
  });
  return <div ref={dom} dangerouslySetInnerHTML={{ __html: content }}></div>;
};
