import * as React from "react";
import { Form } from "semantic-ui-react";
import { FormListenerActions } from "../../pages/lib";

export type LongTextInput = {
  __component: "form.long-text-input";
  label: string;
  name: string;
  is_required: boolean;
};

export const LongTextInputComponent = ({
  label,
  name,
  is_required,
  listener
}: {
  label: string;
  name: string;
  is_required: boolean;
  listener: FormListenerActions;
}) => {
  return (
    <Form.TextArea
      fluid
      label={label}
      required={is_required}
      onChange={listener.handleTextareaChange(name)}
    />
  );
};
