import * as React from "react";
import { Grid, Responsive, Icon } from "semantic-ui-react";
import { Link, LinkProps } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { CMSAPI, Footer } from "../lib/cms-api";

const cms = new CMSAPI();

export default function Footer() {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  const [footer, setFooter] = React.useState<Footer>(null);

  React.useEffect(() => {
    cms.fetchFooter().then(setFooter);
  }, []);

  const NavLink = ({ children, to }: LinkProps) => {
    if (to.toString().startsWith("http")) {
      return <a href={to.toString()}>{children}</a>;
    }
    if (to[0] == "#") {
      return <HashLink to={"/" + to}>{children}</HashLink>;
    }
    return (
      <Link to={(to.toString().startsWith("/") ? "" : "/") + to}>
        {children}
      </Link>
    );
  };

  if (!footer) {
    return null;
  }

  return (
    <footer className={`footer ${isMobile ? "mobile" : ""}`}>
      <Grid>
        <Grid.Row columns={isMobile ? 1 : 4}>
          {footer.sections.map(section => (
            <Grid.Column>
              <h2>{section.title}</h2>
              <ul>
                {section.items.map(item => (
                  <li>
                    <NavLink to={item.url}>{item.text}</NavLink>
                  </li>
                ))}
              </ul>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      {isMobile ? (
        <>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.facebook.com/velocitynetltd/">
              <Icon size="big" name="facebook" />
            </a>
          </div>
          <p
            className="copyright"
            style={{ marginTop: "20px", textAlign: "center" }}
          >
            Copyright © {new Date().getFullYear()} VelocityNet | E&OE
          </p>
        </>
      ) : (
        <>
          <div
            className="social"
            style={{ textAlign: "right", marginTop: "50px" }}
          >
            <a href="https://www.facebook.com/velocitynetltd/">
              <Icon size="big" name="facebook" />
            </a>
          </div>
          <p className="copyright">
            Copyright © {new Date().getFullYear()} VelocityNet | E&OE
          </p>
        </>
      )}
    </footer>
  );
}
