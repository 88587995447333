import * as React from "react";
import { Image } from "semantic-ui-react";
import { cleanURL } from "../../lib/util";
import config from "../../lib/api-config";

export type Image = {
  __component: "pages.image";
  image: { url: string };
};

export const ImageComponent = ({ image_url }: { image_url: string }) => {
  return <Image src={cleanURL(config.CMS.url + image_url)} />;
};
