import * as React from "react";
import { Image } from "semantic-ui-react";
import Lightbox from "react-image-lightbox";
import { IHomePageData } from "../index";
import { cleanURL } from "../../../lib/util";
import config from "../../../lib/api-config";

interface IProps {
  data: IHomePageData;
}

export default (props: IProps) => {
  const [showImage, setShowImage] = React.useState(false);
  const [activeIndex, setIndex] = React.useState(0);
  const { data } = props;

  function openLightboxOnSlide(index: number) {
    setShowImage(true);
    setIndex(index);
  }

  const Gallery = () => {
    let featureImages = data.views_gallery.images.slice(0, 8);
    let index = 0;
    const result = [[], [], [], []];
    for (const image of featureImages) {
      const col = index % 4;
      result[col].push(image);
      index++;
    }
    return (
      <>
        {result.map((col, idx) => (
          <div
            key={idx}
            style={{
              width: "25%",
              display: "inline-block",
              verticalAlign: "top",
              padding: "5px"
            }}
          >
            {col.map((image, imgIdx) => (
              <div key={imgIdx}>
                <Image
                  src={cleanURL(config.CMS.url + image.url)}
                  className="views-image"
                  onClick={() => openLightboxOnSlide(imgIdx * 4 + idx)}
                  style={{ marginBottom: "10px", cursor: "pointer" }}
                />
              </div>
            ))}
          </div>
        ))}
      </>
    );
  };

  const { images } = data.views_gallery;

  return (
    <section className="views-from-us">
      <div className="container">
        <h1 className="title">{data.views_from_us_title}</h1>
        <p className="short-description">{data.views_from_us_subtitle}</p>
        <Gallery />
        <p className="follow-us" style={{ marginTop: "20px" }}>
          Never miss an update.{" "}
          <a href="https://www.facebook.com/velocitynetltd">
            Follow us on Facebook
          </a>
        </p>
      </div>
      {showImage && (
        <Lightbox
          nextSrc={cleanURL(
            config.CMS.url + images[(activeIndex + 1) % images.length].url
          )}
          prevSrc={cleanURL(
            config.CMS.url +
              images[(activeIndex + images.length - 1) % images.length].url
          )}
          mainSrc={cleanURL(config.CMS.url + images[activeIndex].url)}
          onCloseRequest={() => setShowImage(false)}
          onMoveNextRequest={() => setIndex((activeIndex + 1) % images.length)}
          onMovePrevRequest={() =>
            setIndex((activeIndex + images.length - 1) % images.length)
          }
        />
      )}
    </section>
  );
};
