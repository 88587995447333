import * as React from "react";
import Collapsible from "../../../components/collapsible";
import { IHomePageData } from "../index";

interface IProps {
  data: IHomePageData;
}

export default (props: IProps) => {
  const { data } = props;
  return (
    <section className="faq" id="faq">
      <div className="container">
        <h1 className="title">{data.FAQ_title}</h1>
        {data.FAQ_subtitle && <p>{data.FAQ_subtitle}</p>}
        <Collapsible
          className="questions"
          items={data.faqs.faqs.map(faq => ({
            title: faq.question,
            content: faq.answer
          }))}
        />
      </div>
    </section>
  );
};
