import * as React from "react";
import DirectPaymentForm from "./direct-payment-form";
import SignArea from "../../components/sign-area";
import { Form, Modal, Button, Icon } from "semantic-ui-react";
import { HashLink } from "react-router-hash-link";
import { Redirect } from "react-router-dom";

export default () => {
  const [customerNumber, setCustomerNumber] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [customerAddress, setCustomerAddress] = React.useState("");
  const [isValidForm, setIsValidForm] = React.useState(false);
  const [isErrorFromServer, setIsErrorFromServer] = React.useState(false);
  const [isShowSignArea, setShowSignArea] = React.useState(false);
  const [errorFromServer, setErrorFromServer] = React.useState("");

  const [isRedirectToHome, setIsRedirectToHome] = React.useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = React.useState(false);
  const [debitData, setDebitData] = React.useState({});
  const signAreaRef = React.useRef();

  const handleAllValid = () => {
    const isValid = !!customerName && !!customerAddress;
    setIsValidForm(isValid);
  };

  const handleAllNotValid = () => {
    setIsValidForm(false);
  };

  const submitForm = () => {
    const signature = getSignature();
    const json_data = JSON.stringify({
      customerName,
      customerNumber,
      customerAddress,
      signature,
      debitData,
    });
    const url =
      process.env.NODE_ENV === "production"
        ? "/api/debit/update?use_new=true"
        : "http://localhost:8000/api/debit/update?use_new=true";
    fetch(url, {
      method: "POST",
      body: json_data,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status == "success") {
          setIsSubmitSuccess(true);
        } else {
          setIsErrorFromServer(true);
          setErrorFromServer(response.message);
        }
      })
      .catch(() => setIsErrorFromServer(true));
  };

  const getSignature = () => {
    return signAreaRef.current.getCanvasContentAsBase64();
  };

  const clearSignArea = () => {
    if (signAreaRef && signAreaRef.current) {
      signAreaRef.current.clearCanvas();
    }
  };

  const backToHomePage = () => {
    setIsSubmitSuccess(false);
    setIsRedirectToHome(true);
  };
  if (isRedirectToHome) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <h1>Update your direct debit payment details</h1>
      <h2>Customer details</h2>
      <Modal open={isShowSignArea} onClose={() => setShowSignArea(false)}>
        <Modal.Header>Please sign here</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "1.1em" }}>
            Please sign in the box bellow using your mouse or finger if you are
            on mobile
          </p>
          <SignArea ref={signAreaRef} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowSignArea(false)}>Back</Button>
          <Button onClick={clearSignArea} negative>
            Clear
          </Button>
          <Button primary onClick={submitForm}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={isErrorFromServer}
        onClose={() => setIsErrorFromServer(false)}
      >
        <Modal.Header>Direct debit update error</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "1.1em" }}>
            There is something wrong with the server, please contact{" "}
            <HashLink to="/#contact-us">VelocityNet support</HashLink> for more
            information.
            <br />
            <b>Error:</b> {errorFromServer}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsErrorFromServer(false)} primary>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={isSubmitSuccess}>
        <Modal.Header>
          Direct debit info update submitted successfully!
        </Modal.Header>
        <Modal.Content>
          <p style={{ textAlign: "center" }}>
            <Icon name="check circle outline" size="massive" color="green" />
          </p>
          <h2 style={{ textAlign: "center" }}>
            We have received your request to update your direct debit
            information.
          </h2>
          <p
            style={{
              fontSize: "1.1em",
              lineHeight: "1.8em",
              textAlign: "center",
            }}
          >
            We will contact you as soon as possible for more information. If you
            have any question, feel free to{" "}
            <HashLink to="/#contact-us">contact us.</HashLink>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={backToHomePage} primary>
            Back to homepage
          </Button>
        </Modal.Actions>
      </Modal>
      <Form>
        <Form.Input
          fluid
          label="Customer number (optional)"
          onChange={(_, { value }) => setCustomerNumber(value)}
        />
        <Form.Input
          fluid
          label="Customer name"
          onChange={(_, { value }) => setCustomerName(value)}
          required
        />
        <Form.Input
          fluid
          label="Customer address"
          onChange={(_, { value }) => setCustomerAddress(value)}
          required
        />
      </Form>
      <br />
      <DirectPaymentForm
        handleAllNotValid={handleAllNotValid}
        onDataChange={(data) => setDebitData(data)}
        handleAllValid={handleAllValid}
      />
      <br />
      <Form.Button
        disabled={!isValidForm}
        onClick={() => setShowSignArea(true)}
        primary
      >
        Confirm
      </Form.Button>
    </>
  );
};
