import * as React from "react";
import { Form } from "semantic-ui-react";
import { FormListenerActions } from "../../pages/lib";

export type DropdownSelect = {
  __component: "form.dropdown-select";
  label: string;
  name: string;
  is_required: boolean;
  options: Option[];
};

export type Option = {
  label: string;
  value: string;
};

export const DropdownSelectComponent = ({
  label,
  name,
  is_required,
  options,
  listener
}: {
  label: string;
  name: string;
  is_required: boolean;
  options: Option[];
  listener: FormListenerActions;
}) => {
  return (
    <Form.Select
      fluid
      label={label}
      options={options.map(({ label, value }) => ({ text: label, value }))}
      onChange={listener.handleSelectChange(name)}
      required={is_required}
    />
  );
};
