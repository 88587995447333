import * as React from "react";
import { Form, Message } from "semantic-ui-react";
import { formListener, validateFormat } from "../pages/lib";
import AddressInput from "./address-input";
import PhoneInput from "./phone-input";
import ChorusAPI from "../lib/chorus-api";

const chorusAPI = new ChorusAPI();

interface PersonalFormData {
  client_details: {
    fullname: string;
    address_line_1: string;
    address_line_2: string;
    zip_code: string;
    city: string;
    geo_data: string;
    phone: string;
    email: string;
  };
}

export default () => {
  const [phoneError, setPhoneError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(null);
  const [formData, setFormData] = React.useState({
    client_details: {
      fullname: "",
      phone: "",
      email: "",
      city: "",
      address_line_1: "",
      address_line_2: "",
      geo_data: "",
      zip_code: ""
    }
  } as PersonalFormData);
  const listener = formListener(formData, setFormData);
  const isValidForm = () => {
    return (
      formData.client_details.fullname &&
      formData.client_details.address_line_1 &&
      formData.client_details.phone &&
      formData.client_details.email
    );
  };

  const handleFormSubmit = (formData: any) => {
    const url =
      process.env.NODE_ENV === "production"
        ? "/api/lead/create"
        : "http://192.168.16.96:8000/api/lead/create";
    fetch(url, {
      method: "POST",
      body: JSON.stringify(formData)
    })
      .then(res => res.json())
      .then(response => {
        if (response.status == "success") {
          setSubmitSuccess(true);
        } else {
          setSubmitSuccess(false);
        }
      });
  };
  return (
    <>
      {submitSuccess && (
        <Message success>
          We have received your information, we will contact you back as soon as
          possible.
        </Message>
      )}
      {submitSuccess === false && (
        <Message error>
          Ooops! Your submission was unsuccessful, please try again or contact
          us about this.
        </Message>
      )}
      {!submitSuccess && (
        <Form onSubmit={() => handleFormSubmit(formData)}>
          <Form.Input
            fluid
            label="Full name"
            required
            onChange={listener.handleInputChange("client_details.fullname")}
          />
          <AddressInput
            label="Address"
            required
            onSelect={(_, { value }, result) => {
              listener.handleTextareaChange("client_details.address")(null, {
                value
              });
              chorusAPI.getAddressByAid(result.aid).then(result => {
                formData.client_details.address_line_1 =
                  result["formattedAddress"]["line1"];
                formData.client_details.address_line_2 =
                  result["formattedAddress"]["line2"];
                formData.client_details.city =
                  result["structuredAddress"]["town"];
                formData.client_details.zip_code =
                  result["structuredAddress"]["postcode"];
                formData.client_details.geo_data =
                  result["location"]["wgs84Lat"] +
                  "," +
                  result["location"]["wgs84Lon"];
                setFormData(formData);
              });
            }}
            onChange={listener.handleTextareaChange("client_details.address")}
          />
          <PhoneInput
            label="Phone"
            required
            error={
              !validateFormat(
                "phone",
                formData.client_details.phone,
                isValid => {
                  if (phoneError == isValid) {
                    setPhoneError(!isValid);
                  }
                }
              )
                ? { content: "Your phone number is not in the valid format" }
                : null
            }
            onChange={listener.handleInputChange("client_details.phone")}
          />
          <Form.Input
            type="email"
            fluid
            label="Email"
            required
            error={
              !validateFormat(
                "email",
                formData.client_details.email,
                isValid => {
                  if (emailError == isValid) {
                    setEmailError(!isValid);
                  }
                }
              )
                ? { content: "Your email address is not in the valid format" }
                : null
            }
            onChange={listener.handleInputChange("client_details.email")}
          />
          <Form.Button
            disabled={phoneError || emailError || !isValidForm()}
            negative
          >
            Submit
          </Form.Button>
        </Form>
      )}
    </>
  );
};
