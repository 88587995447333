import * as React from "react";
import * as ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "./styles/fonts.css";
import "./styles/loading-icon.css";
import "aos/dist/aos.css";
import AOS from "aos";

import App from "./app";
import { Responsive } from "semantic-ui-react";

const root = document.getElementById("app");

ReactDOM.render(<App />, root, () => {
  AOS.init({});
  if (window.screen.width < Responsive.onlyComputer.minWidth) {
    document.body.classList.add("mobile");
    document.querySelector("html").classList.add("mobile");
  }
});
