import * as React from 'react';
import {Button, Responsive} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Pic404 from '../../../static/404.png';

export default () => {
  const isMobile = screen.width < Responsive.onlyComputer.minWidth;
  return (
    <div className="404-page" style={{textAlign: 'center'}}>
      <img src={Pic404} style={{width: isMobile ? '100%' : '500px'}} />
      <h1>Oops! We can&apos;t find the page that you requested</h1>
      <p>Are you lost? Let&apos;s go back to the home page!</p>
      <Button color="red" as={Link} to="/">
        Go home
      </Button>
    </div>
  );
};
