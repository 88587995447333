import * as React from "react";
import { Grid, Responsive } from "semantic-ui-react";

interface Props {
  background: string;
  height?: string;
  fixed?: boolean;
  bgPosition?: string;
}

export default function Header(props: React.PropsWithChildren<Props>) {
  const isMobile = window.screen.width < Responsive.onlyComputer.minWidth;
  return (
    <section
      className="p-header"
      style={{
        backgroundImage: `url(${props.background})`,
        backgroundSize: "cover",
        backgroundPosition: props.bgPosition,
        backgroundAttachment: props.fixed === false ? "" : "fixed",
        minHeight: !isMobile ? "300px" : "",
        marginLeft: isMobile ? "-20px" : "calc(-15vw - 20px)",
        marginRight: isMobile ? "-20px" : "calc(-15vw - 20px)",
        marginTop: isMobile ? "-22px" : "-40px",
        marginBottom: "50px",
        color: "white",
        position: "relative",
        height: isMobile ? "auto" : props.height || "auto"
      }}
    >
      <div
        className="mask"
        style={{
          background: "rgba(0, 0, 0, 0.4)",
          position: isMobile ? "initial" : "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          padding: `50px ${isMobile ? "20px" : "calc(15vw + 20px)"}`
        }}
      >
        <Grid>
          <Grid.Row columns={isMobile ? 1 : 2}>
            <Grid.Column>{props.children}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </section>
  );
}
