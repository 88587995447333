import * as React from 'react';
import {Helmet} from 'react-helmet';
import {Label} from 'semantic-ui-react';

const codes = [
  {
    title: 'General codes',
    children: [
      {
        label: '*55',
        content: 'Access Voicemail Portal',
      },
      {
        label: '*99',
        content: 'Voice Portal Menus',
      },
    ],
  },
  {
    title: 'Forwarding and Locate me',
    children: [
      {label: '*72xxx', content: 'Call Forward Always Activation'},
      {label: '*73', content: 'Call Forward Always Deactivation'},
      {label: '*92xxx', content: 'Call Forward No Answer Activation'},
      {label: '*93', content: 'Call Forward No Answer Deactivation'},
      {label: '*90xxx', content: 'Call Forward on Busy Activation'},
      {label: '*91', content: 'Call Forward on Busy Deactivation'},
    ],
  },
  {
    title: 'Do not disturb and Privacy options',
    children: [
      {label: '*78', content: 'Do Not Disturb Activation'},
      {label: '*79', content: 'Do Not Disturb Deactivation'},
      {label: '*30', content: 'Caller ID Blocking Activation'},
      {label: '*31', content: 'Caller ID Blocking Deactivation'},
      {label: '*77', content: 'Anonymous Call Rejection Activation'},
      {label: '*87', content: 'Anonymous Call Rejection Deactivation'},
      {
        label: '*60xxx',
        content: 'Selective Call Rejection (Blacklist) Addition',
      },
      {
        label: '*80xxx',
        content: 'Selective Call Rejection (Blacklist) Removal',
      },
      {label: '*65xxx', content: 'Make a call with Caller ID visible'},
      {label: '*67xxx', content: 'Make a call with Caller ID blocked'},
      {label: '*32', content: 'Anonymous caller screening Activation'},
      {label: '*33', content: 'All callers screening Activation'},
      {label: '*34', content: 'Call screening Deactivation'},
    ],
  },
  {
    title: 'Other options',
    children: [
      {label: '*61', content: 'Call Waiting Activation'},
      {label: '*81', content: 'Call Waiting Deactivation'},
      {label: '*69', content: 'Call Return (Call back your last caller)'},
      {label: '*66', content: 'Last Number Redial'},
      {label: '*51', content: 'Who last called me?'},
      {label: '*37xxx', content: 'Set Authorisation Pin Code'},
      {
        label: '*37',
        content: 'Remove Authorisation Pin Code (no digits after *37)',
      },
      {label: '*74x', content: 'Program Speed Dial 8 (x can be 2-9)'},
      {
        label: '*52',
        content: 'Toggle to activate/deactivate Velocitynet voicemail system',
      },
      {
        label: '*54n',
        content: 'Set Call Diversion Timer where ‘n’ is number of seconds',
      },
    ],
  },
  {
    title: 'Feature codes during a call',
    children: [
      {
        label: '##',
        content:
          'Perform a ‘blind’ transfer to another number (if not disabled)',
      },
      {
        label: '#0',
        content:
          'Perform an ‘attended’ transfer to another number (if not disabled)',
      },
      {
        label: '*1',
        content: 'Start/Stop a manual recording of a call (if not disabled)',
      },
      {
        label: '*0',
        content: 'Disconnect from a call',
      },
    ],
  },
];

function pad(str: string, size: number) {
  var s = str;
  while (s.length < (size || 2)) {
    s += ' ';
  }
  return s;
}

export default function PhoneFeatureCodes() {
  return (
    <div className="phone-feature-codes section">
      <Helmet>
        <title>VelocityNet | Phone Feature Codes</title>
      </Helmet>
      <h1>Phone Feature Codes</h1>
      <p>
        <b>Question: </b>What are the * codes for changing my Velocitynet
        features settings via the phone?
      </p>
      <p>
        <b>Answer: </b>The following star codes can be dialed from your
        Velocitynet phone to setup various features from your handset.
      </p>
      <p>
        <b>Wherever you see ‘xxx’ below, this refers to a number you enter.</b>
      </p>
      {codes.map(code_group => (
        <React.Fragment key={code_group.title}>
          <h3>{code_group.title}</h3>
          {code_group.children.map(code => (
            <div key={code.label} style={{marginBottom: '10px'}}>
              <Label size="large" color="red" style={{marginRight: '10px'}}>
                <pre style={{margin: '0'}}>{pad(code.label, 6)}</pre>
              </Label>
              {code.content}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}
