import * as React from "react";
import { Button, ButtonGroup, ButtonOr } from "semantic-ui-react";

export const PlanViewer = ({ business, residential }) => {
  const [isBusiness, setIsBusiness] = React.useState(false);
  return (
    <>
      <p style={{ textAlign: "center" }}>
        <ButtonGroup>
          <Button negative={isBusiness} onClick={() => setIsBusiness(true)}>
            Business
          </Button>
          <ButtonOr />
          <Button negative={!isBusiness} onClick={() => setIsBusiness(false)}>
            Residential
          </Button>
        </ButtonGroup>
      </p>
      <br />
      <br />
      {isBusiness ? business : residential}
    </>
  );
};
