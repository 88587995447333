import * as React from "react";
import { ButtonGroup, Button, ButtonOr } from "semantic-ui-react";

export type MultipleChoiceQuestionFOP = {
  __component: "find-out-plan.multiple-choice";
  question: string;
  name: string;
  choices: Choice[];
  step: number;
};

export type Choice = {
  label: string;
  jump_to_name: string;
};

export const MultipleChoiceQuestionFOPComp = ({
  step,
  question,
  choices,
  setJump
}: {
  step: number;
  question: string;
  choices: Choice[];
  setJump: (location: string, index: number) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  React.useEffect(() => {
    let choice = choices[selectedIndex];

    if (!choice) {
      return;
    }

    setJump(choice.jump_to_name, step + 1);
  }, [selectedIndex]);

  const choiceButtons = choices.map((choice, idx) => {
    const selected = selectedIndex === idx;
    return (
      <Button negative={selected} onClick={() => setSelectedIndex(idx)}>
        {choice.label}
      </Button>
    );
  });

  return (
    <div>
      <h2>Question {step + 1}:</h2>
      <p>{question}</p>
      <ButtonGroup>
        {choiceButtons.map((choice, idx) => {
          const isLast = idx == choices.length - 1;
          if (!isLast) {
            return [choice, <ButtonOr />];
          }
          return choice;
        })}
      </ButtonGroup>
    </div>
  );
};
