import * as React from "react";
import { Grid } from "semantic-ui-react";
import { IHomePageData } from "../index";

interface IProps {
  data: IHomePageData;
}

export default (props: IProps) => {
  const { data } = props;
  const { info_card } = data;
  return (
    <section className="contact" id="contact-us">
      <div className="container">
        <div className="left">
          <h1 className="title">{data.Contact_us_title}</h1>
          <p>{data.Contact_us_subtitle}</p>
        </div>
        <div className="right">
          <div className="contact-card">
            <Grid>
              <Grid.Row columns={2}>
                {info_card.addresses.map(address => (
                  <Grid.Column key={address.type}>
                    <h2>{address.type}</h2>
                    <p>{address.address}</p>
                  </Grid.Column>
                ))}
              </Grid.Row>
              <Grid.Row columns={1} style={{ marginTop: "30px" }}>
                <Grid.Column>
                  <h2>Business Hours</h2>
                  <p>
                    {info_card.business_hour.days_of_week}
                    <br />
                    {info_card.business_hour.time}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} style={{ marginTop: "30px" }}>
                <Grid.Column>
                  <h2>Phone</h2>
                  <p>
                    {info_card.phones.map(phone => (
                      <span key={phone.name}>
                        <b>{phone.name}:</b> {phone.number}
                        <br />
                      </span>
                    ))}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} style={{ marginTop: "30px" }}>
                <Grid.Column>
                  <h2>Email</h2>
                  <p>
                    {info_card.emails.map(email => (
                      <span key={email.name}>
                        <b>{email.name}:</b> {email.email}
                        <br />
                      </span>
                    ))}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
      <iframe width="100%" height="570" id="map" src={data.map.url}></iframe>
    </section>
  );
};
